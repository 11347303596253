import React from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import styles from "assets/jss/material-dashboard-react/layouts/profileStyle";

function Profile(props) {
  const classes = styles();

  return (
    <div className={classes.root}>
      <IconButton onClick={props.backAction}>
        <ArrowBackIcon />
      </IconButton>
      <Typography className={classes.title}>{props.title}</Typography>
      <Typography className={classes.subtitle}>{props.subtitle}</Typography>
    </div>
  );
}

export default Profile;
