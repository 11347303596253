import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import styles from "assets/jss/material-dashboard-react/components/listItemStyle";

function ListItems(props) {
  const classes = styles(props);
  const { doneIcon, lable, onClick, status, img, disabled, secondary, selected, ...rest } = props;



  return (
    <div className={classes.root}>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.list}
      >
        <Card className={classes.card} {...rest}>
          <ListItem button disabled={disabled} onClick={onClick} selected={selected}>
            {img ? (
              <img src={img} alt="..." className={classes.img} />
            ) : (
              <IconButton>{doneIcon}</IconButton>
            )}

            <ListItemText
              primary={lable}
              className={classes.lable}
              disableTypography
              secondary={secondary ? <span  style={{ color: 'red', float: 'right' }}>Under Verification</span>:""}
            />
            <span className={classes.span}>{status}</span>
            <ListItemIcon className={classes.icon}>
              <KeyboardArrowRightIcon />
            </ListItemIcon>
          </ListItem>
        </Card>
      </List>
    </div>
  );
}

export default ListItems;
