import { makeStyles } from "@material-ui/core/styles";
import dropdownStyle from "assets/jss/material-dashboard-react/dropdownStyle.js";

const useStyles = makeStyles((theme) => ({
  ...dropdownStyle(theme),
  root: {
    width: "100%",
    fontSize: "100px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },

  actionsIcons: {
    marginBottom: "-5px",
    margin: theme.spacing(0.5),
  },

  bodycontent: {
    color: "##1A1A1A",
    fontWeight: "500",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    textDecoration: "none",
    fontSize: "14px",
  },
}));

export default useStyles;
