import { makeStyles } from "@material-ui/core/styles";

const customcardStyles = makeStyles((theme) => ({
  root: {
    background: "#F5F5FA",
    paddingTop: "5px",
    padding: "10px",
  },
  icon: {
    color: "#3929EA",
    display: "flex",
    margin: "auto",
    opacity: 1,
    marginTop: "40px",
  },
  card: {
    marginTop: "0",
    height: "192px",
    marginBottom: "0px",
    borderRadius: "4px",
  },
  text: {
    textAlign: "center",
    color: "#000000",
    opacity: 0.9,
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "bold",
  },
  description: {
    textAlign: "center",
    color: "#000000",
    opacity: 1,
    fontSize: "12px",
    lineHeight: "16px",
  },
  img: {
    height: "192px",
    display: "flex",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      //width: "100%",
    },
  },
  input: {
    border: "1px solid #E6E9EE",
    height: "48px",
    textAlign: "left",
    fontSize: "16px",
    lineHeight: "20px",
    background: "#FFFFFF",
    borderRadius: "4px",
    color: "#000000",
    marginTop: "10px",
    padding: "10px",
    opacity: 0.7,
  },
  subtext:{
    color:" #f8931d",
    opacity: 0.3
  },
}));

export default customcardStyles;
