import React , { useState, useRef}from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from "@material-ui/core/styles";

const options = ['Yesterday','Today'];

const useStyles = makeStyles((theme) => ({
    button: {
      backgroundColor: (props) => props.backgroundcolor,
      color: "#5244ec",
      borderRadius: "25px",
      width: "85px",
      border: "blue",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        fontSize: "8px",
      },
      marginRight: "10px",
      fontWeight: "bold",
      fontSize: "8px",
      border: (props) => `1px solid ${props.border}`,
      "&:hover": {
        backgroundColor: (props) => props.backgroundcolor,
      },
    },
  }));

export default function SplitButton(props) {
    const classes = useStyles(props);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(1);
    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
      };
    
      const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
      };
    
      const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
    
        setOpen(false);
      };

      return (
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
              <Button  ref={anchorRef} aria-label="split button"  onClick={handleToggle} endIcon={<KeyboardArrowDownIcon/>} className={classes.button}>
              {options[selectedIndex]}
              </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === "top-start",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        {options.map((option, index) => (
                          <MenuItem
                            key={option}
                            disabled={index === 2}
                            selected={index === selectedIndex}
                            onClick={(event) => handleMenuItemClick(event, index)}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </Grid>
      );
    }