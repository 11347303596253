import { makeStyles } from "@material-ui/core/styles";

const userprofileStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F5F5FA",
  },
  padding: {
    padding: "10px",
  },
  paddingTop: {
    paddingTop: "40px",
  },

  card: {
    marginLeft: "10px",
    marginRight: "10px",
    background: "#FFFFFF",
    borderRadius: "4px",
    opacity: 1,
    marginBottom: "0px",
    backgroundColor: (props) => (props.selected ? "#EAE8FF" : ""),
  },
  
  list: {
    width: "100%",
    padding: "5px",
    paddingLeft: "0px",
  },

  input: {
    flex: 1,
    border: "1px solid #E2E6EA",
    height: "48px",
    textAlign: "left",
    fontSize: "16px",
    lineHeight: "20px",
    background: "#FFFFFF",
    borderRadius: "4px",
    color: "#000000",
    marginTop: "5px",
    padding: "10px",
  },

  card: {
    padding: "0px",
    marginTop: "10px",
  },
  cardHeader: {
    backgroundColor: "#FDE57D",
    borderRadius: "10px",
    boder: "1px solid blue",
    marginLeft: "10px",
    marginRight: "10px",
  },
  span: {
    marginLeft: "20px",
  },

  map: {
    height: "192px",
    display: "flex",
    margin: "auto",
    marginTop: "10px",
    width: "328px",
  },
}));

export default userprofileStyles;
