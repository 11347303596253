import { makeStyles } from "@material-ui/core/styles";

const listitemStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F5F5FA",
  },
  list: {
    width: "100%",
    padding: "5px",
    paddingLeft: "0px",
  },
  lable: {
    color: "#000000",
    opacity: 0.7,
    textAlign: "left",
    fontSize: "16px",
    lineHeight: "20px",
  },
  card: {
    marginLeft: "10px",
    marginRight: "10px",
    background: "#FFFFFF",
    borderRadius: "4px",
    opacity: 1,
    marginBottom: "0px",
    backgroundColor: (props) => (props.selected ? "#EAE8FF" : ""),
  },

  icon: {
    justifyContent: "flex-end",
  },

  span: {
    textAlign: "left",
    fontSize: "12px",
    lineHeight: "16px",
  },
  img: {
    width: "24px",
    marginRight: "10px",
    height: "35px",
    opacity: 1,
  },
}));

export default listitemStyles;
