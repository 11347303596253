import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useStyles from "../../assets/jss/material-dashboard-react/components/loginpageStyle";
import Congratsimage from "../../../src/assets/img/Congrats.png"
import { useHistory } from "react-router-dom";



function Congrats(){
    const classes = useStyles();
    const history = useHistory();
    const login = () => {
        history.push("/auth/login");
      };

    return (
        <div>
        <div className='congrats'><img 
        src={Congratsimage}
        alt="Congrats"
        /></div>
        <Typography component="div">
        
            <Box fontWeight="fontWeightBold" className={classes.boxhead} textAlign="center" m={1}>
                 Congratulation!
            </Box>
            <Box fontWeight="fontWeightLight" className={classes.boxbody} m={1}>
                Your account has been successfully created with skyld. Please go to login page to sign into your skyld account
            </Box>
        <Button type="submit"
                color="primary"
                fullWidth
                className={classes.submit} onClick={login}>Login</Button>
               
               </Typography>
               </div>
    );

}

export default Congrats;
