import React from "react";
import Card from "./Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "0px",
    backgroundColor: (props) => (props.selected ? "#EAE8FF" : ""),
    [theme.breakpoints.down("xs")]: {
      height: "110px",
      borderRadius: "8px",
      opacity: 1,
      width: "100px",
    },
  },
  media: {
    marginTop: "20px",
    width: "40%",
    paddingLeft: "20px",
    paddingTop: 0,
  },

  content: {
    height: 20,
    textAlign: "left",
  },
  Typography: {
    color: "#000000CC",
    width: "100px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  cardaction: {
    [theme.breakpoints.down("xs")]: {
      height: "110px",
      borderRadius: "8px",
    },
  },
}));

function Categorycard(props) {
  const classes = useStyles(props);

  const { imgurl, cardtitle, onClick } = props;

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={onClick} className={classes.cardaction}>
        <CardMedia>
          <img src={imgurl} alt="..." className={classes.media}></img>
        </CardMedia>
        <CardContent className={classes.content}>
          <Typography
            className={classes.Typography}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {cardtitle}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default Categorycard;
