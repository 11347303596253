import React, { useState } from "react";
// import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import SchoolOutlinedIcon from "@material-ui/icons/SchoolOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import NewReleasesOutlinedIcon from "@material-ui/icons/NewReleasesOutlined";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { Container, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import GridContainer from "Components/Grid/GridContainer.js";
import GridItem from "Components/Grid/GridItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// import useStyles from "../../assets/jss/material-dashboard-react/components/HomePageStyles.js";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
}));

export default function HomePage() {
  const classes = useStyles();

  return (
    <Container>
      <div align="left">
        <p>
          <b>More</b>
        </p>
      </div>
      <div className={classes.root}>
        <Avatar
          src="https://www.w3schools.com/howto/img_avatar.png"
          className={classes.large}
        />
      </div>
      <div style={{ textAlign: "center" }}>
        <Typography>
          <b>Rahul Sharma</b>
        </Typography>
        <Typography variant="caption">User ID : 234567</Typography>
      </div>
      <GridContainer direction="row" alignItems="center" spacing={2}>
        <Grid item>
          <CreditCardOutlinedIcon
            style={{ color: "#1976d2" }}
            fontSize="small"
          />
        </Grid>
        <Grid item>My Credits</Grid>
      </GridContainer>
      <GridContainer direction="row" alignItems="center" spacing={2}>
        <Grid item>
          <ListAltOutlinedIcon style={{ color: "#e57373" }} fontSize="small" />
        </Grid>
        <Grid item>Task History</Grid>
      </GridContainer>
      <GridContainer direction="row" alignItems="center" spacing={2}>
        <Grid item>
          <AccountBalanceWalletOutlinedIcon
            style={{ color: "#26a69a" }}
            fontSize="small"
          />
        </Grid>
        <Grid item>Payment History</Grid>
      </GridContainer>
      <GridContainer direction="row" alignItems="center" spacing={2}>
        <Grid item>
          <EventOutlinedIcon style={{ color: "#d32f2f" }} fontSize="small" />
        </Grid>
        <Grid item>My Calendar</Grid>
      </GridContainer>
      <GridContainer direction="row" alignItems="center" spacing={2}>
        <Grid item>
          <SchoolOutlinedIcon style={{ color: "#42a5f5" }} fontSize="small" />
        </Grid>
        <Grid item>Training</Grid>
      </GridContainer>
      <GridContainer direction="row" alignItems="center" spacing={2}>
        <Grid item>
          <SettingsOutlinedIcon style={{ color: "#b388ff" }} fontSize="small" />
        </Grid>
        <Grid item>Settings</Grid>
      </GridContainer>

      <AppBar position="fixed" color="white" className={classes.appBar}>
        <Container>
          <Toolbar style={{ justifyContent: "space-between" }}>
            <GridContainer direction="column" alignItems="center" spacing={0}>
              <Grid item>
                <AssignmentOutlinedIcon
                  style={{ color: "#616161" }}
                  fontSize="small"
                />
              </Grid>
              <Typography variant="caption">TASK</Typography>
            </GridContainer>
            <GridContainer direction="column" alignItems="center" spacing={0}>
              <Grid item>
                <NewReleasesOutlinedIcon
                  style={{ color: "#616161" }}
                  fontSize="small"
                />
              </Grid>
              <Typography variant="caption">WINS</Typography>
            </GridContainer>
            <GridContainer direction="column" alignItems="center" spacing={0}>
              <GridItem>
                <LocalAtmOutlinedIcon
                  style={{ color: "#616161" }}
                  fontSize="small"
                />
              </GridItem>
              <Typography variant="caption">INCOME</Typography>
            </GridContainer>
            <GridContainer direction="column" alignItems="center" spacing={0}>
              <GridItem>
                <MoreHorizOutlinedIcon
                  style={{ color: "#616161" }}
                  fontSize="small"
                />
              </GridItem>
              <Typography variant="caption">MORE</Typography>
            </GridContainer>
          </Toolbar>
        </Container>
      </AppBar>
    </Container>
  );
}