import React ,{useState, useEffect}from "react";
import ProfileLayout from "Components/Header/Profile";
import map from "assets/img/download.jpeg";
import Card from "Components/Card/Card";
import Button from "Components/CustomButtons/Button";
import styles from "assets/jss/material-dashboard-react/views/userProfile";
import { cityServiceArea, serviceAreaToUser} from "../../Utils/ApiEndpoints";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useForm } from "react-hook-form";
import {callAPI} from "../../Utils/HttpUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: 30,
    borderRadius: "12px",
    backgroundColor: (props) => (props.selected ? "#E3E2F8" : null),
    wordWrap: "unset",
    opacity: 1
  },
}));




function CustomizedBreadcrumbs(props) {
  const classes = useStyles(props);
  return (
    <Typography className={classes.root} onClick={props.onClick}>
      {props.name}
    </Typography>
  );
}

function ServiceArea(props) {
  const classes = styles();
  const [selected, setSelected] = useState([]);
  const [serviceArea, setServiceArea] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });

  const {  handleSubmit } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      serviceAreaId :[],
    },
  });

  useEffect(() => {
      console.log("cityid", props.vendorData.userDetails.cityDTO);
      callAPI("POST",cityServiceArea,props.vendorData.userDetails.cityDTO, successAreaCallback, errorAreaCallback);
  }, []);

  const successAreaCallback = (responseData) => {
    console.log('responseData',JSON.stringify(responseData.data.responseData));
    setServiceArea(responseData.data.responseData);
    setLoaded(true);
  }
  
  
  const errorAreaCallback = (err) => {
    console.log('err',JSON.stringify(err.response));
    let errMsg = "Oops! error in service. Kindly try after sometime or reach out to us at support@skyld.in";       
    seterror({
      error: true,
      message: errMsg,
      key: Math.random(),
    });
  }

  const handleserviceareaupdate = async () => {
    let details = {
      serviceAreaIds :selected,
    };
    callAPI("post",serviceAreaToUser,details,updateServiceAreSuccessCallback ,errorAreaCallback);    
  };


  const updateServiceAreSuccessCallback = (responseData) => {
    console.log('responseData',JSON.stringify(responseData));
    if (responseData.data.message === "SUCCESS") {            
      props.secondary(true);        
      props.onClick();
    } else {
      seterror({
        error: true,
        message: responseData.data.message,
        key: Math.random(),
      });
    }
  }
  

  const handleClick = (event, name) => {
    event.preventDefault();
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }; 
  const isSelected = (name) => {return selected.indexOf(name) !== -1};


  return (
    <div>
      <ProfileLayout
        title={"Add Services area"}
        subtitle={"Select your service location near you"}
        backAction={props.onClick}
      />
        {loaded ? (          
      <form 
        className={classes.form} 
        noValidate  
        onSubmit ={handleSubmit(handleserviceareaupdate)}
       >
          <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
        {serviceArea.map((prop, index) => {
            const isItemSelected = isSelected(prop.serviceAreaId.toString());
            console.log("serviceAreaId", prop.serviceAreaId.toString());
            console.log("name", prop.name);
            return (
              <CustomizedBreadcrumbs
                key={index}
                name={prop.name}
                onClick={(event) => {
                  handleClick(event, prop.serviceAreaId.toString());
                }}
                selected={isSelected(prop.serviceAreaId.toString())}
              >
              </CustomizedBreadcrumbs>
            );
          })}
          </div>
          <Card>
            <img src={map} alt="...." className={classes.map} />
          </Card>
        <Button
          //onClick={handleserviceareaupdate}
            variant="contained"
            color="primary"
            type="submit"
            className={classes.submit}
        >
          ADD
        </Button>
    </form>
          )
    : null }
  </div>
  );
}

export default ServiceArea;
