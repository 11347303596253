import * as Cookies from "js-cookie";

export const setCookie = (access_token, refresh_token) => {
  Cookies.set(
    "access_token",
    access_token,
    { expires: 1 },
    { httpOnly: true }
  );
  Cookies.set(
    "refresh_token",
    refresh_token,
    { expires: 1 },
    { httpOnly: true }
  );
};

export const storeDetails = (user_role, name, ProfileStatus) => {
  localStorage.setItem("UserRole", user_role);
  localStorage.setItem("Name", name);
  localStorage.setItem("ProfileStatus", ProfileStatus);
};

export const readDetails = (key) => {
  return localStorage.getItem(key);
};

export const readCookie = () => {
  const access_token = Cookies.getJSON("refresh_token");
  if (access_token) {
    return true;
  } else {
    return false;
  }
};

export const readCookieByName = (cookieName) => {
  const cookie = Cookies.getJSON(cookieName);
  if (cookie) {
    return cookie;
  } else {
    return null;
  }
};

export const removeSession = () => {
  Cookies.remove("access_token");
  Cookies.remove("refresh_token");
  localStorage.removeItem("UserRole");
  localStorage.removeItem("Name");
  localStorage.removeItem("ProfileStatus");
};
