import React from "react";
import { Route, Redirect } from "react-router-dom";
import { readCookie, readDetails } from "../Utils/Session";

function ProfileRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        readCookie() && readDetails("ProfileStatus") === "Registered" ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/task/tasksection" }} />
        )
      }
    />
  );
}

export default ProfileRoute;
