import React, { useState } from "react";
import ProfileLayout from "Components/Header/Task";
import TaskLayout from "Components/Header/Task";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import styles from "assets/jss/material-dashboard-react/views/taskStyle";
import className from "classnames";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import GridContainer from "Components/Grid/GridContainer.js";
import GridItem from "Components/Grid/GridItem.js";
import CButton from "Components/CustomButtons/CustomButtonTask";
import CustomButton from "Components/CustomButtons/CustomButtonDis";
import Typography from '@material-ui/core/Typography';
import ChatIcon from '@material-ui/icons/Chat';
import CallIcon from '@material-ui/icons/Call';
import InputBase from '@material-ui/core/InputBase';
import { useHistory } from "react-router-dom";
import CustomButtonDis from "Components/CustomButtons/CustomButtonDis";
import SplitButton from "../../Components/SplitButton/Splitbutton";


export default function TaskSection(props) {
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([
    { id: 1, name: "All Task", value:"10" },
    { id: 2, name: "New Task" , value:"2" },
    { id: 3, name: "Completed Task ", value:"10" },
    { id: 4, name: "WIP Task", value:"2" },
    { id: 5, name: "Cancelled Task", value:"10" },
    { id: 6, name: "Delayed Task", value:"2" },
    { id: 7, name: "Rescheduled Task", value:"10" },
  ]);

  const [status, setStatus]= useState([
    { id: 1, name: "New" },
    { id: 2, name: "Done" },
    { id: 3, name: "WIP" },
    { id: 4, name: "Cancelled" },
    { id: 5, name: "Delayed" },
    { id: 6, name: "Rescheduled" },
  ]);
  const [route, setroute] = useState();
  const [checked, setChecked] = useState([1]);
  const history= useHistory();

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const classes = styles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  // const handleClick = () => {
  //   setOpen(!open);
  // };

  return (
      <div className={className(classes.root)}>
        {!route ? (
          <>
            <div style ={{marginBottom:"70px"}}>
            <GridContainer>
              <GridItem xs={6} md={8}>
                <Typography variant="h4">Task</Typography>
              </GridItem>
              <GridItem xs={6} md={4}>
              <SplitButton style={{marginTop : "20px"}}
                    backgroundcolor="#f5f5fa"
                    border="#5244ec"
              />
                  {/* <CustomButtonDis>
                    name
                  </CustomButtonDis> */}
                </GridItem>
              </GridContainer>
            </div>
            <div></div>
            {data.map((prop, index) => {
              const isItemSelected = isSelected(prop.name);
              return (
                <Card className={classes.card}>
               <List
                component="div" id="nested-list-subheader"
              >
              <ListItem button>
                <ListItemText  primary={prop.name} 
                   key={index}
                    onClick={(event) => {
                      handleClick(event, prop.name);
                      setroute(prop.name);
                    }}
                />
                  <div>
                    <ListItemText disableTypography 
                      secondary={prop.value} />
                  </div>
                    </ListItem>
              </List>
              </Card> 
              );
              
            })}
          </>
        ) : (
          <>
            <ProfileLayout title={route} 
             backAction={() => setroute()} />
            <Card
            onClick={(event) => {
                  handleClick();
                  // setroute(prop.name);
                  console.log("Clicked");
                  history.push("/admin/taskscreen/123");
                }}>
            <CardContent>
                <GridContainer>
                  <GridItem xs={6} md={6}>
                  <Typography  variant="h6" component="h2" className={classes.text}>
                    Vivek Solanki
                  </Typography>
                  <Typography className={classes.subtext}>
                    Home Painting
                  </Typography>
                  </GridItem>
                  <GridItem xs={6} md={6}>
                  {status.map((prop, index) => {
                    return <CustomButton
                    disabled
                    backgroundcolor="#F5F0D5"
                    border="#E9DFA5"
                    name={prop.name}
                    className={classes.text}
                  >{prop.name}</CustomButton>
                  })}
                  <CustomButtonDis>
                    name
                  </CustomButtonDis>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} md={6}>
                  <InputBase
                      className={classes.input}
                      inputProps={{ 'aria-label': 'naked' }}
                      disabled
                      >
                      TEXT
                      </InputBase>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem >
                    <CButton
                    backgroundcolor="#00A9F4"
                    onClick={() => console.log("CHAT")}>
                      <ChatIcon />
                          CHAT
                    </CButton>
                  </GridItem>
                  <GridItem>
                    <CButton backgroundcolor="#0CBF59"
                    onClick={() => console.log("CALL")}>
                      <CallIcon/>
                          CALL
                    </CButton>
                  </GridItem>
                </GridContainer>
              </CardContent>
            </Card>
          </>
        )}
      </div>
    );
  }
