
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import {readCookieByName} from "./Session"

export const callAPIOpen = (method, path, details, successCallback, errorCallback) => {
    trackPromise(
        axios({
            method: method,
            url: path,
            headers: {
                "Content-Type": "application/json",
            },
            data: details,
        })
            .then((responseData) => {
                successCallback(responseData);
            })
            .catch((err) => {
                errorCallback(err);
            })
    )
}



export const callAPI = (method, path, details, successCallback, errorCallback) => {
    trackPromise(
        axios({
            method: method,
            url: path,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+ readCookieByName('access_token')
            },
            data: details,
        })
            .then((responseData) => {
                successCallback(responseData);
            })
            .catch((err) => {
                errorCallback(err);
            })
    )
}