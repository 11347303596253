import React, { useRef, useCallback } from "react";
import Typography from "@material-ui/core/Typography";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";							
import styles from "assets/jss/material-dashboard-react/components/customCardStyle";
import { useForm } from "react-hook-form";

function DocumentUpload(props) {
  const inputRef = useRef();
  const classes = styles();
  const errors = useForm();
  const {
    actionUpload,
    description,
    selected,
    inputText,
    imageHandler,
    onChange,
    input,
    inputref,
    error,
    ...rest
  } = props;

  const fileUpload = useCallback(() => {
    inputRef.current.click();
  }, []);

  return (
    <div className={classes.root} {...rest}>
      {selected ? (
        <>
          <img
            src={selected}
            alt="...."
            className={classes.img}
            onClick={fileUpload}
          />

          <input
            accept="image/*"
            style={{ display: "none" }}
            id="icon-button-file"
            type="file"
            onChange={imageHandler}
            ref={inputRef}
          />
        </>
      ) : (
        <>
          <Card className={classes.card}>
            <CardContent>
              <div>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="icon-button-file"
                  type="file"
                  onChange={imageHandler}
                  ref={inputRef}
                />
                <IconButton
                  aria-label="upload picture"
                  className={classes.icon}
                  onClick={fileUpload}
                >
                  <PhotoCameraIcon />
                </IconButton>
              </div>
              <Typography className={classes.text}>{"Upload Photo"}</Typography>
              <Typography className={classes.description}>
                {description}
              </Typography>
            </CardContent>
          </Card>
        </>
      )}

      { selected ? (
        <>
          {/* <form noValidate autoComplete="off" className={classes.form}>
            <InputBase
              className={classes.input}
              placeholder={inputText}
              fullWidth
              name={inputText}
              onChange={onChange}
              inputRef={inputref}
              error = {true}
            />
            {error}
          </form> */}
          {/* <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.submit}
            disabled={selected && input ? false : true}
            onClick={actionUpload}
          >
            SUBMIT AND NEXT
          </Button> */}

          <div >
        </div>
        </>
      ) : null}
    </div>
  );
}
export default DocumentUpload;