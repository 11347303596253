import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Sidebar from "Components/Sidebar/Sidebar.js";

import routes from "routes.js";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import logo from "assets/img/Capa_1.png";
import { readDetails } from "../Utils/Session";
import AdminNavbarLinks from "Components/Navbars/AdminNavbarLinks.js";
import Header from "Components/Header/Header";

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/profile") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/profile" to="/admin/dashboard" />
  </Switch>
);
const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const classes = useStyles();

  const getRoute = () => {
    console.log("Data : ", readDetails("UserRole"));
    return window.location.pathname !== "/admin/maps";
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.wrapper}>
      <Header rightLinks={<AdminNavbarLinks />} logo={logo} />
      <div className={classes.mainPanel}>
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
      </div>
    </div>
  );
}
