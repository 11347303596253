import React, { useState, useEffect } from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { useForm } from "react-hook-form";
// core Components
import GridItem from "Components/Grid/GridItem.js";
import GridContainer from "Components/Grid/GridContainer.js";
import Button from "Components/CustomButtons/Button";
import Card from "Components/Card/Card.js";
import TextField from "@material-ui/core/TextField";
import CardAvatar from "Components/Card/CardAvatar.js";
import InputLabel from "@material-ui/core/InputLabel";
import CardBody from "Components/Card/CardBody.js";
import CardFooter from "Components/Card/CardFooter.js";
import useStyles from "../../assets/jss/material-dashboard-react/components/loginpageStyle";
import Avatar from "@material-ui/core/Avatar";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import profileImage from "../../assets/img/marc.jpg";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import axios from "axios";
import {getUserUrl, getCities, addUserUrl, uploadImage} from "../../Utils/ApiEndpoints";
import {callAPI} from "../../Utils/HttpUtil";
import {readCookieByName} from "../../Utils/Session"

export default function UserProfile(props) {
  const classes = useStyles();
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
    profileImage
  );
  const [cities, setCities] = useState("");
  const [citiesDb, setCitiesDb] = useState("");
  const [loaded, setloaded] = useState(false);

const { register, handleSubmit, errors } = useForm({
  mode: "onChange",
  reValidateMode: "onChange",
  defaultValues: {
    firstName: null,
    lastName: null,
    cellPhone: "",
    postalAddress:"",
    gender:"",
    city: "",
    dob: ""
  },
});


function SubmitButton(){
  if (userObj.firstName && userObj.lastName && userObj.postalAddress && userObj.cellPhone && userObj.gender && userObj.city && !imagePreviewUrl.includes('static/media')){
    return <Button 
    variant="contained"
    color="primary"
    type="submit"
    className={classes.submit}>SUBMIT AND NEXT</Button>
  } else {
    return <Button disabled 
    variant="contained"
    color="primary"
    type="submit"
    className={classes.submit}>SUBMIT AND NEXT</Button>
  };
};

  const [userObj, setUserObj] = useState({
    firstName: "",
    lastName: "",
    cellPhone: "",
	  gender: "",
	  city: "",
	  postalAddress: "",
	  dob: ""
  });
  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });

  const onChange = (e) => {
    setUserObj({ ...userObj, [e.target.name]: e.target.value });
  };

  const handleChangeGender = (e) => {
    setUserObj({ ...userObj, gender: e.target.value });
  };
  const handleChangeCity = (e) => {
    setUserObj({ ...userObj, city: e.target.value });
  };
  const [selectedDate, setSelectedDate] = useState(
    new Date("1970-01-01")    
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const uploadFile = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file !== null && file !== undefined) {
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("model", "9886640263");
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          "Authorization": "Bearer "+ readCookieByName('access_token')
        },
      };
      axios
        .post(uploadImage, formData, config)
        .then((response) => {
          alert("The file is successfully uploaded");
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    {
      callAPI("post",getUserUrl,{}, profileSuccessCallback, profileErrorCallback);
    };
  }, []);

  const profileSuccessCallback = (responseData) => {
    console.log('responseData',JSON.stringify(responseData));

    if (responseData.data.message === "SUCCESS") { 
      const userData = responseData.data.responseData;

      if(userData.firstName !== null && userData.firstName !== undefined && userData.firstName !== '' 
        && userData.lastName !== null && userData.lastName !== undefined && userData.lastName !== '' 
        && userData.cellPhone !== null && userData.cellPhone !== undefined && userData.cellPhone !== '' 
        && userData.gender !== null && userData.gender !== undefined && userData.gender !== '' 
        && userData.cityDTO !== null && userData.cityDTO !== undefined && userData.cityDTO !== '' 
        && userData.postalAddress !== null && userData.postalAddress !== undefined && userData.postalAddress !== '' 
        && userData.dob !== null && userData.dob !== undefined && userData.dob !== '')
      {
        props.history.push("/profile/category", responseData.data.responseData);
      } else {   
        if(userData.photo !== null) {
          setImagePreviewUrl("data:image/png;base64," + userData.photo);
        }
        setUserObj({
          firstName: userData.firstName,
          lastName: userData.lastName,
          cellPhone: userData.cellPhone,
          gender: userData.gender,
          city: (userData.cityDTO === null || userData.cityDTO === undefined || userData.cityDTO === '') ? '':userData.cityDTO.cityId,
          postalAddress: (userData.postalAddress === null || userData.postalAddress === undefined || userData.postalAddress === '') ? '' : userData.postalAddress,
          dob: userData.dob
        });
        callAPI("GET",getCities,{}, citiesSuccessCallback, profileErrorCallback);      

      }      
      
    } else {
      seterror({
        error: true,
        message: responseData.data.message,
        key: Math.random(),
      });
    }
  }

  const citiesSuccessCallback = (responseData) => {
    console.log('responseData',JSON.stringify(responseData.data.responseData));
    setCitiesDb(responseData.data.responseData);
    setCities(responseData.data.responseData.map((city,index) => {
      return <MenuItem key={index} value={city.cityId}>{city.cityName}</MenuItem>
    }));   
    setloaded(true);
  }
  
  const profileErrorCallback = (err) => {
    console.log('err',JSON.stringify(err.response));
    let errMsg = "Oops! error in service. Kindly try after sometime or reach out to us at support@skyld.in";
    if(err.response.data.message === "") {
      errMsg = "";
    }          
    seterror({
      error: true,
      message: errMsg,
      key: Math.random(),
    });
  }

  const setprofileSuccessCallback = (responseData) => {
    console.log('responseData',JSON.stringify(responseData));
    if (responseData.data.message === "SUCCESS") {            
      props.history.push("/profile/category", responseData.data.responseData);
    } else {
      seterror({
        error: true,
        message: responseData.data.message,
        key: Math.random(),
      });
    }
  }

  const handleProfile = async(e)=>{
    let citylocal = citiesDb.filter(function (obj) { 
      return obj.cityId=== userObj.city; 
    })[0];
    const details ={
      firstName: userObj.firstName,
      lastName: userObj.lastName,
      cellPhone: userObj.cellPhone,
      dob: selectedDate,
      gender: userObj.gender,
      cityDTO: citylocal,
      postalAddress: userObj.postalAddress,
    }
    callAPI("post",addUserUrl,details, setprofileSuccessCallback, profileErrorCallback);
  }

  return (
    <form
      className={classes.form}
      noValidate
      onSubmit={handleSubmit(handleProfile)}
    >
    {loaded ? (
    <>
      <p className={classes.cardTitleWhite} style={{ color: "black" }}>
        Complete your Profile
      </p>
      <GridContainer>
        <GridItem xs={12} sm={12} md={7}>
          <Card profile>
            <CardBody>
              <CardAvatar>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="icon-button-file"
                  type="file"
                  onChange={uploadFile}
                />
                <label htmlFor="icon-button-file">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <Avatar  src={imagePreviewUrl} className={classes.large} />
                  </IconButton>
                </label>
              </CardAvatar>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    value={userObj.firstName}
                    error={!!errors.fname}
                    inputRef={register({
                      required: "You must provide the First name!",
                      pattern: {
                      value: /^[a-zA-Z]*$/,
                      message: "You must provide the First name!",
                      },
                    })}
                    onChange={onChange}
                  />
                  {errors.fname && (
                    <span className={classes.error}>{errors.fname.message}</span>
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    name="lastName"
                    label="Last Name"
                    id="lastName"
                    error={!!errors.lname}
                    value={userObj.lastName}
                    inputRef={register({
                      required: "You must provide the last name!",
                      pattern: {
                        value: /^[a-zA-Z]*$/,
                        message: "You must provide the last name!",
                      },
                      
                    })}
                    onChange={onChange}
                  />
                  {errors.lname && (
                    <span className={classes.error}>{errors.lname.message}</span>
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    name="cellPhone"
                    label="Phone Number"
                    id="cellPhone"
                    value={userObj.cellPhone}
                    error={!!errors.cellPhone}
                    disabled
                    inputRef={register({
                      required: "You must provide the Phone Number!",
                      pattern: {
                        value: /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/,
                        message: "You must provide the Phone Number!",
                      },
                      
                    })}
                    onChange={onChange}
                  />
                  {errors.cellPhone && (
                    <span className={classes.error}>{errors.cellPhone.message}</span>
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      margin="normal"
                      fullWidth
                      color="primary"
                      id="dob"
                      label="Date of Birth"
                      format="dd-MM-yyyy"
                      value={selectedDate}
                      InputAdornmentProps={{ position: "start" }}
                      onChange={(date) => handleDateChange(date)}
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-filled">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled"
                      id="gender"
                      fullWidth
                      value={userObj.gender}
                      onChange={handleChangeGender}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-filled">
                      City
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled"
                      id="city"
                      fullWidth
                      value={userObj.city}
                      onChange={handleChangeCity}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {cities}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    id="postalAddress"
                    label="Complete Address"
                    name="postalAddress"
                    value={userObj.postalAddress} 
                    error={!!errors.postalAddress}
                    inputRef={register({
                      required: "You must provide the Address!",
                      pattern: {
                      value:"^[a-zA-Z0-9!@#$&()\\-`.+,/\"]*$",
                      message: "You must provide the Address!",
                      },
                    })}
                    onChange={onChange}
                  />
                    {errors.postalAddress && (
                    <span className={classes.error}>{errors.postalAddress.message}</span>
                    )}
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter className={classes.footer}>
              <SubmitButton/>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      </>
    )
    : null }
    </form>
  );
}
