import React from "react";
// nodejs library that concatenates classes
// nodejs library to set properties for components

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import styles from "assets/jss/material-dashboard-react/components/custombuttonStyle.js";

export default function customButton(props) {
  const classes = styles();
  const { children, ...rest } = props;

  return (
    <Button {...rest} className={classes.submit}>
      {children}
    </Button>
  );
}
