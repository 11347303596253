import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/core
// @material-ui/icons

// core components
import GridItem from "Components/Grid/GridItem.js";
import GridContainer from "Components/Grid/GridContainer.js";
// import Card from "Components/Card/Card.js";

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
        

  return (
    <div className={classes.section}>
      {/* <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <div className={classes.title}>

            <h3>Menu</h3>
            </div>
            </GridItem>
        </GridContainer> 
        </div> */}
            </div> 
  );
}
