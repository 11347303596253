import React, { useState , useEffect } from "react";
import ProfileLayout from "Components/Header/Profile";
import ListItem from "Components/ListItems/ListItem";
import Button from "Components/CustomButtons/Button";
import className from "classnames";
import styles from "assets/jss/material-dashboard-react/views/userProfile";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { skillsToUser, cityCategorySkills} from "../../Utils/ApiEndpoints";
import {callAPI} from "../../Utils/HttpUtil";
import { useForm } from "react-hook-form";


const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: 30,
    borderRadius: "12px",
    backgroundColor: (props) => (props.selected ? "#E3E2F8" : null),
    wordWrap: "unset",
    opacity: 1
  },
}));

function CustomizedBreadcrumbs(props) {
  const classes = useStyles(props);
  return (
    <Typography className={classes.root} onClick={props.onClick}>
      {props.name}
    </Typography>
  );
}

function Skills(props) {
  const [selected, setSelected] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [categories, setCategories] = useState([]);
  const [skills, setSkills] =useState([]);
  const [route, setroute] = useState();
  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });

  const classes = styles();

  const {  handleSubmit } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      categoryIds :[],
      skillsDtoList :[]
    },
  });

  useEffect(() => {
    console.log("cityid", props.vendorData.userCategoryDTOList);
    callAPI("POST",cityCategorySkills,props.vendorData.userDetails.cityDTO, categorySuccessCallback, categoryErrorCallback);
}, []);


const categorySuccessCallback = (responseData) => {
  let userCategories = [];
  props.vendorData.userCategoryDTOList.forEach(element => {
    userCategories.push(element.categoryId);
  });
  let result = [];
  responseData.data.responseData.forEach(element => {
    let dataObj = {};
    dataObj.categoryName = element.categoryName;
    if(userCategories.indexOf(element.categoryId) !== -1) {
      dataObj.selected = true;
      // check if user skills are available, then iterate for category skills and check in user skills
      if (props.vendorData.userSkillsDTO !== null && props.vendorData.userSkillsDTO !== undefined && props.vendorData.userSkillsDTO.length >0){
        let status ='';
        element.skillsDtoList.forEach(skillObj => {
          props.vendorData.userSkillsDTO.forEach(userSkillObj => {
            if(userSkillObj.skillId === skillObj.skillId){
              if(userSkillObj.status === 'Verification'){
                status= "Under Verification"
              }else if((userSkillObj.status === 'Approved' || userSkillObj.status === 'Rejected') && status !== "Under Verification" ) {
                status= "Completed"
              }
            }
          } )
        });
        dataObj.status =status;
      }
    } else {
      dataObj.selected = false;
    }
    dataObj.skills = element.skillsDtoList;
    result.push(dataObj);
  });
  console.log('responseData',JSON.stringify(responseData.data.responseData));
  setCategories(result);
  setLoaded(true);
}

const categoryErrorCallback=(err) => {
  console.log('err',JSON.stringify(err.response));
    let errMsg = "Oops! error in service. Kindly try after sometime or reach out to us at support@skyld.in";
    if(err.response.data.message === "") {
      errMsg = "";
    }          
    seterror({
      error: true,
      message: errMsg,
      key: Math.random(),
    });

};

const handleskillupdate = async () => {
  let details = {
    skillsIds :selected,
  };
  callAPI("post",skillsToUser,details,updateSkillsSuccessCallback ,categoryErrorCallback);    
};

const updateSkillsSuccessCallback = (responseData) => {
  console.log('responseData',JSON.stringify(responseData));
  if (responseData.data.message === "SUCCESS") { 
    props.onClick(setroute(null));
  } else {
    seterror({
      error: true,
      message: responseData.data.message,
      key: Math.random(),
    });
  }
}

const loadSkillsFromCategory = (category) => {
  setSkills(category.skills);
}

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={className(classes.root)}>
      {!route ? (
        <>
          <ProfileLayout title={"Add Skills"} backAction={props.onClick} />
          {loaded ? (
          <form 
            className={classes.form} 
            noValidate 
            // onSubmit ={handleSubmit(handleskillupdate)}
          >
          {categories.map((prop, index) => {
            const isItemSelected = isSelected(prop.categoryName);
            return (
              <ListItem
                lable={prop.categoryName}
                key={index}
                status={prop.status}
                disabled ={prop.status.length > 0? true : false}
                onClick={(event) => {
                  loadSkillsFromCategory(prop);
                  setroute(prop.categoryName);
                }}
                selected={prop.selected}
              />
            );
          })}
          <Button
          //onClick ={handleskillupdate}
          variant="contained"
          color="primary"
          type="submit"
          className={classes.submit}
        >
          SAVE SKILLS
        </Button>
        </form>
          )
        : null }
        </>
      ) : (
        <>
          <ProfileLayout title={route} backAction={() => setroute()} />
          <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {skills && skills.map((prop, index) => {
            const isItemSelected = isSelected(prop.skillId);
            return (
              <CustomizedBreadcrumbs
                key={index}
                name={prop.skillName}
                onClick={(event) => {
                  handleClick(event, prop.skillId);
                }}
                selected={isSelected(prop.skillId)}
              />
            );
          })}
          </div>
          <div >
        <Button
          // onClick={() => setroute(null)}
          onClick ={handleskillupdate}
          variant="contained"
          color="primary"
          type="submit"
          className={classes.submit}
        >
          DONE
        </Button>
        {/* //<SubmitButtonSkills/> */}
        </div>
        </>
      )}
    </div>
  );
}

export default Skills;
