import { makeStyles } from "@material-ui/core/styles";

const profilelayoutStyles = makeStyles((theme) => ({
  root: {
    background: "#F5F5FA",
  },
  title: {
    textAlign: "left",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#000000",
    opacity: 0.9,
    marginLeft: "14px",
    fontWeight: 600,
  },
  subtitle: {
    textAlign: "left",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#000000",
    opacity: 0.9,
    marginLeft: "14px",
    paddingBottom: "10px",
  },
}));

export default profilelayoutStyles;
