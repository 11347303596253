import { makeStyles } from "@material-ui/core/styles";

const customButtonStyles = makeStyles((theme) => ({
  submit: {
    display: "flex",
    margin: "auto",
    backgroundColor: "#3929EA",
    "&:hover": {
      backgroundColor: "#3929EA",
    },
    color: "#FFFFFF",
    height: "50px",
    borderRadius: "4px",
    opacity: 1,
    fontSize: "16px",
    lineHeight: "20px",
    width: "296px",
    bottom: "10px",
    position: "fixed",
    right: theme.spacing(2),
    left: theme.spacing(2),
    marginTop: "10px",
  },
}));

export default customButtonStyles;
