import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import { Link,NavLink, useLocation } from "react-router-dom";
import useStyles from "../../assets/jss/material-dashboard-react/views/forgotPassword";
import { useForm } from "react-hook-form";
import QueryString from "qs";
import AlertMessage from "../../Components/Snackbar/SnackbarError";
import InfoMessage from "../../Components/Snackbar/SnackbarInfo";
import {callAPIOpen} from "../../Utils/HttpUtil";
import {verifyTokenUrl,verifyOtpUrl,resendOtpUrl} from "../../Utils/ApiEndpoints";

const VerifyOTP = (props) => {
  const { state } = useLocation();
  const [open, setOpen] = useState(false);

  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });

  const [info, setInfo] = useState({
    info: null,
    message: null,
    key: null,
  });
  

  const token = QueryString.parse(props.location.search, { ignoreQueryPrefix: true }).token;
  console.log("state", state);
  const verifytokenSuccessCallback = (responseData) => {
    console.log('responseData',JSON.stringify(responseData));
          if (responseData.data.message === "VERIFIED") {            
            props.history.push("/auth/setpassword", responseData.data.responseData);
          } else if(responseData.data.message === "ACTIVE") {
            //Alert user about password already set and redirect to login page
            setOpen(true);
          } else {
            seterror({
              error: true,
              message: responseData.data.message,
              key: Math.random(),
            });
          }
  }

  const verifytokenErrorCallback = (err) => {
    console.log('err',JSON.stringify(err.response));
          let errMsg = "Invalid Token";
          seterror({
            error: true,
            message: errMsg,
            key: Math.random(),
          });
  }

  useEffect(() => {
  if(token !== null && token !== undefined) {
    const details = {
      token: token
    }
    callAPIOpen("post",verifyTokenUrl,details, verifytokenSuccessCallback, verifytokenErrorCallback);
  };
}, []);

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      phone: state !== null && state !== undefined ? state.userName :"",
    },
  });
  const [phone,setPhone] = useState(state !== null && state !== undefined ? state.userName :""); 
  const [otp, setOtp] = useState(new Array(4).fill(""));

  const handleChange = (event, index) => {
    let element = event.target;
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    if ( event.target.value === '' || event.target.value === undefined || event.target.value === null) {
      // Focus on the previous field
      if (element.previousSibling) {
        element.previousSibling.focus();
      }
    } else if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const verifyotpSuccessCallback = (responseData) => {
    if (responseData.data.message === "VERIFIED") {            
      props.history.push("/auth/setpassword", responseData.data.responseData);
    } else if(responseData.data.message === "ACTIVE") {
      //Alert user about password already set and redirect to login page
      setOpen(true);
    } else {
      seterror({
        error: true,
        message: responseData.data.message,
        key: Math.random(),
      });
    }
  }

  const verifyotpErrorCallback = (err) => {
    let errMsg = "Oops! error in service. Kindly try after sometime or reach out to us at support@skyld.in";
    if(err.response.data.message === "INVALID_TOKEN") {
      errMsg = "Phone or OTP is invalid!";
    }          
    seterror({
      error: true,
      message: errMsg,
      key: Math.random(),
    });
  }

  const handleverifyotp = () => {
    const details = {
      userName:phone,
      otp:otp[0]+''+otp[1]+''+otp[2]+''+otp[3]
    }
    callAPIOpen("post",verifyOtpUrl,details, verifyotpSuccessCallback, verifyotpErrorCallback); 
  };

  const handleClose = () => {
    setOpen(false);
    props.history.push("/auth/login");
  };

  const resendotpSuccessCallback = (responseData) => {
    if (responseData.data.message === "SUCCESS") {      
      setInfo({
        info: true,
        message: "OTP has been shared via email and SMS, kindly verify the OTP.",
        key: Math.random(),
      });
    } else {
      seterror({
        error: true,
        message: responseData.data.message,
        key: Math.random(),
      });
    }
  }

  const resendotpErrorCallback = (err) => {
    let errMsg = "Oops! error in service. Kindly try after sometime or reach out to us at support@skyld.in";
    seterror({
      error: true,
      message: errMsg,
      key: Math.random(),
    });
  }

  const onClick = () =>{
    console.log('phone : ',phone)
    if(phone !== null && phone !== undefined) {
      const details = {
        userName:phone
      }
      callAPIOpen("post",resendOtpUrl,details, resendotpSuccessCallback, resendotpErrorCallback); 
    } else {
      seterror({
        error: true,
        message: "Phone ",
        key: Math.random(),
      });
    }
    
  }

  function SubmitButton(){
    if(phone === null || phone === undefined || phone === '') {
      setPhone(state !== null && state !== undefined && phone !== "" ? state.userName :"");
    }
    if (phone && otp[0] && otp[1] && otp[2] && otp[3]){
      return <Button fullWidth
      variant="contained"
      color="primary"
      type="submit"
      className={classes.submit}>Verify OTP</Button>
    } else {
      return (
        <Button
          disabled
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          className={classes.submit}
        >
          Verify OTP
        </Button>
      );
    }
  }

  const classes = useStyles();

  return (
    <form
      className={classes.form}
      noValidate
      onSubmit={handleSubmit(handleverifyotp)}
    >
      {error.error ? (
        <AlertMessage key={error.key} message={error.message} />
      ) : null}
      {info.info ? (
        <InfoMessage key={info.key} message={info.message} />
      ) : null}
      <h4>OTP Verification!</h4>
      <div>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          disabled={state}
          name="phone"
          label="Phone Number"
          id="phone"
          error={!!errors.phone}
          inputRef={register({
            required: "Mobile number is required.",
            pattern: {
              value: /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/,
              message: "Invalid Mobile number",
            },
          })}
          onChange={(e) => setPhone(e.target.value)}
        />
        {errors.phone && (
          <span className={classes.error}>{errors.phone.message}</span>
        )}
      </div>
      <div className="row">
        <div className="col text-center">
          {otp.map((data, index) => {
            return (
              <input
                className="otp-field"
                type="text"
                name="otp"
                maxLength="1"
                key={index}
                value={data}
                onChange={e => handleChange(e, index)}
                onFocus={e => e.target.select()}
              />
            );
          })}
          <Link to="#" variant="body2" className={classes.alink} onClick = {handleSubmit(onClick)}>
            Resend OTP
          </Link>
          <SubmitButton/>
          <NavLink to="/auth/login" variant="body2" className={classes.alink}>
            Signin
          </NavLink>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Account Active"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Your account is already in active state. Kindly use your credentials to login to the application.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default VerifyOTP;
