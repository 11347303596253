import axios from "axios";
import React from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import { Alert, AlertTitle } from "@material-ui/lab";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import Divider from "@material-ui/core/Divider";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TableHeader from "Components/Table/TableHeader";
import TableToolbar from "Components/Table/TableToolbar";
import useStyles from "assets/jss/material-dashboard-react/components/usertableStyle";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import { getComparator, stableSort } from "../../Utils/sortFunctions";

const headers = [
  { id: "firstname", label: "First Name" },
  { id: "lastname", label: "Last Name" },
  { id: "phonenumber", label: "Phone Number" },
  { id: "email", label: "Email" },
  { id: "createdAt", label: "Created At" },
  { id: "actions", label: "Actions" },
];

function UserTable() {
  const [data, setData] = React.useState([]);
  const [error, seterror] = React.useState(false);
  const [errorMessage, setErrorMessages] = React.useState();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const classes = useStyles();

  const actionhandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const actionhandleClose = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  React.useEffect(() => {
    axios
      .get("https://5f4d36cbeeec51001608e9b5.mockapi.io/users")
      .then((res) => {
        if (res.data.items) {
          setData(res.data.items);
          seterror(false);
        }
      })
      .catch((error) => {
        seterror(true);
        setErrorMessages("Dummy testing error!!");
        console.log(error);
      });
  }, []);

  return (
    <div className={classes.root}>
      <GridContainer spacing={1}>
        <GridItem item xs={12} sm={12} lg={12}>
          <div>
            {error ? (
              <Alert severity="error">
                {<AlertTitle>{errorMessage}</AlertTitle>}
              </Alert>
            ) : null}
          </div>
          <Paper className={classes.paper}>
            <TableToolbar
              numSelected={selected.length}
              TableTitle={"Users"}
              color="primary"
              className={classes.root}
              Icon={<FilterListIcon />}
            />

            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <TableHeader
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={data.length}
                  headers={headers}
                />
                <TableBody>
                  {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((prop, key) => {
                      const isItemSelected = isSelected(prop.id);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={prop.id}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onClick={(event) => handleClick(event, prop.id)}
                              color="primary"
                            />
                          </TableCell>

                          <TableCell
                            align="left"
                            className={classes.bodycontent}
                          >
                            {prop.firstname}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.bodycontent}
                          >
                            {prop.lastname}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.bodycontent}
                          >
                            {prop.phonenumber}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.bodycontent}
                          >
                            {prop.email}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.bodycontent}
                          >
                            {prop.createdAt}
                          </TableCell>

                          <TableCell align="left">
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={actionhandleClick}
                            >
                              <MoreVertIcon fontSize={"small"} />
                            </IconButton>
                            <Menu
                              role="menu"
                              id="fade-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={open}
                              onClose={actionhandleClose}
                              TransitionComponent={Fade}
                            >
                              <MenuItem
                                onClick={actionhandleClose}
                                className={classes.dropdownItem}
                              >
                                <EditIcon
                                  size={"small"}
                                  className={classes.actionsIcons}
                                />
                                Edit
                              </MenuItem>
                              <Divider light />
                              <MenuItem
                                onClick={actionhandleClose}
                                className={classes.dropdownItem}
                              >
                                <DeleteIcon
                                  size={"small"}
                                  className={classes.actionsIcons}
                                />
                                Delete
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 1 * emptyRows }}></TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default UserTable;
