// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import Person from "@material-ui/icons/Person";
import SignIn from "./views/UserManagement/Signinpage";
import Signup from "./views/UserManagement/Signuppage";
import forgotPassword from "./views/UserManagement/ForgotPassword";
import VerifyOTP from "./views/UserManagement/VerifyOTP";
import Users from "./views/Dashboard/UsersTable";
import UserProfile from "./views/UserManagement/UserProfile";
import Profile from "./views/UserManagement/Profile";
import NewPassword from "./views/UserManagement/NewPassword";
import CongratsPage from "./views/UserManagement/CongratsPage";
import EmailPage from "./views/UserManagement/EmailPage";
import ProfileSection from "views/UserProfileScreens/ProfileSection";
import Category from "views/UserProfileScreens/Category";
import TaskSection from "./views/TaskScreens/TaskSection";
import TaskScreen from "./views/TaskScreens/TaskScreen";
import homePage from "./views/HomePage/Homepage";
import { AddToHomeScreen, Home } from "@material-ui/icons";

// core components/views for RTL layout

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard UI",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    render:true,
  },

  {
    path: "/users",
    name: "Users UI",
    icon: PeopleIcon,
    component: Users,
    layout: "/admin",
    render:true,
  },

  {
    path: "/userprofile",
    name: "User Profile",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
    render:true,
  },
  {
    path: "/login",
    name: "Login",
    icon: Dashboard,
    component: SignIn,
    layout: "/auth",
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    icon: Dashboard,
    component: forgotPassword,
    layout: "/auth",
  },
  {
    path: "/signup",
    name: "Sign Up",
    icon: Dashboard,
    component: Signup,
    layout: "/auth",
  },
  {
    path: "/verifyotp",
    name: "Verify OTP",
    icon: Dashboard,
    component: VerifyOTP,
    layout: "/auth",
  },
  {
    path: "/setpassword",
    name: "Set Password",
    icon: Dashboard,
    component: NewPassword,
    layout: "/auth",
  },
  {
    path: "/congrats",
    name: "Congrats",
    icon: Dashboard,
    component: CongratsPage,
    layout: "/auth",
  },
  {
    path: "/email",
    name: "Email",
    icon: Dashboard,
    component: EmailPage,
    layout: "/auth",
  },

  {
    path: "/profile",
    name: "Profile",
    //icon: Person,
    component: Profile,
    layout: "/profile",
  },
  {
    path: "/edit",
    name: "Edit",
    //icon: Person,
    component: ProfileSection,
    layout: "/profile",
  },
  {
    path: "/category",
    name: "Category",
    //icon: Person,
    component: Category,
    layout: "/profile",
  },
  {
    path: "/tasksection",
    name: "Task Section",
    icon: Person,
    component: TaskSection,
    layout: "/admin",
    render:true,
  },
  {
    path: "/taskscreen/:id",
    name: "Task Screen",
    icon: Person,
    component: TaskScreen,
    layout: "/admin",
    render:false,
  },
  {
    path: "/HomePage",
    name: "Home Page",
    icon: Home,
    component: homePage,
    layout: "/admin",
    render: true,
  },
  
];

export default dashboardRoutes;
