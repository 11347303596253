import React, { useState } from "react";
import ProfileLayout from "Components/Header/Profile";
import Documentcard from "Components/Card/DocumentUploadCard";
import Card from "Components/Card/Card.js";
import Button from "Components/CustomButtons/Button";
import styles from "assets/jss/material-dashboard-react/views/userProfile";
import axios from "axios";
import {saveChequeDetails, addVendorBankDetails} from "../../Utils/ApiEndpoints";
import {readCookieByName} from "../../Utils/Session";
import {callAPI} from "../../Utils/HttpUtil";
import GridContainer from "Components/Grid/GridContainer.js";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import CardBody from "Components/Card/CardBody.js";

function BankDetails(props) {
  const [values, setValues] = useState({
    ifscCode: null,
    bankName: null,
    accountNumber: null,
    reenteraccountNumber: null,
    branchName:null
  });
  const [cheque, setcheque] = useState();
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      bankName: null,
      branchName: null,
      ifscCode: "",
      accountNumber:"",
      reenteraccountNumber:"",
    },
  });
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const classes = styles();
  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });

  const handlebankdetailupdate = async (e) => {
    let details = {
      ifscCode: values.ifscCode,
      bankName: values.bankName,
      accountNumber: values.accountNumber,
      reenteraccountNumber: values.reenteraccountNumber,
      branchName : values.branchName
    };
    callAPI("post",addVendorBankDetails,details, suceessBankUpload, errorBankUpload);    
  };

  const suceessBankUpload = (responseData) => {
    console.log('responseData',JSON.stringify(responseData));
    if (responseData.data.message === "SUCCESS") {    
      props.secondary(true);        
      props.onClick();
    } else {
      seterror({
        error: true,
        message: responseData.data.message,
        key: Math.random(),
      });
    }
  }
  
  const errorBankUpload = (err) => {
    console.log('err',JSON.stringify(err.response));
    let errMsg = "Oops! error in service. Kindly try after sometime or reach out to us at support@skyld.in";       
    seterror({
      error: true,
      message: errMsg,
      key: Math.random(),
    });
  }

  const handleChequeupdateimage = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if(file !== null && file !== undefined) {
          reader.onloadend = () => {
            setcheque(reader.result);
          }
      reader.readAsDataURL(e.target.files[0]);
      const formData = new FormData();
        formData.append('file',file);   
          const config = {
              headers: {
                  'content-type': 'multipart/form-data',
                  "Authorization": "Bearer "+ readCookieByName('access_token')
              }
          };
      axios
          .post(saveChequeDetails, formData, config)
          .catch((error) => {});
      }
    };

  return (
    <div >
    <form
      className={classes.form}
      noValidate
    >
    <>
    <ProfileLayout
        title={"Bank account details"}
        subtitle={"Upload focused photos of below documents for verification"}
        backAction={props.onClick}
      />
      <GridContainer>
     <Card profile>
            <CardBody>
                <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    id="bankName"
                    label="Bank Name"
                    name="bankName"
                    error={!!errors.bankName}
                    inputRef={register({
                      required: "You must provide the Bank name!",
                      pattern: {
                      value: /^[a-zA-Z]*$/,
                      message: "You must provide the Bank name!",
                      },
                    })}
                    onChange={onChange}
                  />
                  {errors.bankName && (
                    <span className={classes.error}>{errors.bankName.message}</span>
                  )}
                <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    name="branchName"
                    label="Branch Name"
                    id="branchName"
                    error={!!errors.branchName}
                    inputRef={register({
                      required: "You must provide the Branch name!",
                      pattern: {
                        value: /^[a-zA-Z]*$/,
                        message: "You must provide the Branch name!",
                      },
                    })}
                    onChange={onChange}
                  />
                  {errors.branchName && (
                    <span className={classes.error}>{errors.branchName.message}</span>
                  )}
                <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    name="ifscCode"
                    label="IFSC Code"
                    id="ifscCode"
                    error={!!errors.ifscCode}
                    inputRef={register({
                      required: "Incorrect IFSC Code!",
                      pattern: {
                        value: /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/,
                        message: "Incorrect IFSC Code!",
                      },
                    })}
                    onChange={onChange}
                  />
                  {errors.ifscCode && (
                    <span className={classes.error}>{errors.ifscCode.message}</span>
                  )}
                  <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    id="accountNumber"
                    label="Account Number"
                    name="accountNumber"
                    error={!!errors.accountNumber}
                    inputRef={register({
                      required: "You must provide the Account Number!",
                      pattern: {
                      value: /^\d{9,18}$/,
                      message: "You must provide the Account Number!",
                      },
                    })}
                    onChange={onChange}
                  />
                  {errors.accountNumber && (
                    <span className={classes.error}>{errors.accountNumber.message}</span>
                  )}
                  <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    id="reenteraccountNumber"
                    label="Re-enter account number"
                    name="reenteraccountNumber"
                    error={!!errors.reenteraccountNumber}
                    inputRef={register({
                      required: "Please re-enter the account number!",
                      pattern: {
                      value: /^\d{9,18}$/,
                      message: "Please re-enter the account number!",
                      },
                    })}
                    onChange={onChange}
                  />
                  {errors.reenteraccountNumber && (
                    <span className={classes.error}>{errors.reenteraccountNumber.message}</span>
                  )}
                  <Documentcard
                      description={
                        "You can upload photo of your cheque leaf with your clear name and account number"
                      }
                      actionUpload={handleChequeupdateimage}
                      className={classes.card}
                      selected={cheque}
                      imageHandler={handleChequeupdateimage}
                  />
                </CardBody>
                    <div >
                      <Button
                        onClick={handlebankdetailupdate}
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.submit}
                      >
                        SUBMIT
                      </Button>
                    </div>
        </Card>
      </GridContainer>
      </>
    </form>
    </div>
  );
}

export default BankDetails;
