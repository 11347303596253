import React, { useState } from "react";
import ProfileLayout from "Components/Header/Profile";
import styles from "assets/jss/material-dashboard-react/views/taskStyle";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import GridContainer from "Components/Grid/GridContainer.js";
import GridItem from "Components/Grid/GridItem.js";
import CustomButton from "Components/CustomButtons/CustomButtonDis";
import CustomButtonNew from "Components/CustomButtons/CustomButtonNew";
import Typography from '@material-ui/core/Typography';
import CallIcon from '@material-ui/icons/Call';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors'; 
import NearMeIcon from '@material-ui/icons/NearMe';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import clsx from 'clsx';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from "react-router-dom";

export default function TaskSection(props) {
    const history= useHistory();
    const classes = styles();
    const [status, setStatus]= useState([
        { id: 1, name: "New" },
        { id: 2, name: "Done" },
        { id: 3, name: "WIP" },
        { id: 4, name: "Cancelled" },
        { id: 5, name: "Delayed" },
        { id: 6, name: "Rescheduled" },
      ]);
      const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


    return (
        <>
        <ProfileLayout title="Task"  backAction= {() => history.push("/admin/tasksection")}/>
            <Card>
              <CardContent>
              <GridContainer>
                  <GridItem xs={6} md={6}>
                  <Typography  variant="h6" component="h2" className={classes.text}>
                    Vivek Solanki
                  </Typography>
                  <Typography className={classes.subtext}>
                    Home Painting
                  </Typography>
                  </GridItem>
                  <GridItem xs={6} md={6}>
                  {status.map((prop, index) => {
                    return <CustomButton
                    value="Reassign"
                    disabled
                    backgroundcolor="#F5F0D5"
                    border="#E9DFA5"
                    name={prop}
                    className={classes.text}
                  />;
                  })}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} md={6}>
                  <InputBase
                      className={classes.input}
                      inputProps={{ 'aria-label': 'naked' }}
                      disabled
                      >
                      TEXT
                      </InputBase>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} md={6}>
                  <InputBase
                      className={classes.input}
                      inputProps={{ 'aria-label': 'naked' }}
                      disabled
                      >
                      TEXT
                      </InputBase>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={4} md={4}>
                    <CustomButtonNew
                    onClick={() => console.log("CALL")}>
                      <CallIcon style={{ color: green[500] }}/>
                          CALL
                    </CustomButtonNew>
                  </GridItem>
                  <GridItem xs={4} md={4}>
                    <CustomButtonNew
                    onClick={() => console.log("NearMe")}>
                      <NearMeIcon color= "primary"/>
                          2.3Km
                    </CustomButtonNew>
                  </GridItem>
                  <GridItem xs={4} md={4}>
                    <CustomButtonNew
                    onClick={() => console.log("Help")}>
                      <LiveHelpIcon color= "secondary"/>
                          Help
                    </CustomButtonNew>
                  </GridItem>
                </GridContainer>
              </CardContent>
            </Card>
            <Card onClick={handleExpandClick}>
                <CardActions disableSpacing>
                <Typography>Task Details</Typography>
                    <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography>
           Task Details
          </Typography>
        </CardContent>
      </Collapse>
    </Card>

    <Card onClick={handleExpandClick}>
                <CardActions disableSpacing>
                <Typography>Work Description</Typography>
                    <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    >
                    <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                    <Typography>
                    Work Description
                    </Typography>
                    </CardContent>
                </Collapse>
                </Card>
    <div>
    <Button fullWidth
      variant="contained"
      color="primary"
      type="submit"
      className={classes.submit}
      onClick={() => console.log("Start work")}>START WORK</Button>
    </div>
    </>
    );
}