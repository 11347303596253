import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { NavLink, useLocation } from "react-router-dom";
import useStyles from "../../assets/jss/material-dashboard-react/views/forgotPassword";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import { useForm } from "react-hook-form";
import {callAPIOpen} from "../../Utils/HttpUtil";
import {newPasswordUrl} from "../../Utils/ApiEndpoints";

const NewPassword = (props) => {
    const { state } = useLocation();
    console.log('State : ',JSON.stringify(state));
    if(state === undefined || state === null) {
        props.history.push("/auth/login");
    }
    const classes = useStyles();
    const [passwordShown, setPasswordShown] = useState(false);
    const [nPassword, setNPassword] = useState('');
    const [cPassword, setConfirmPassword] = useState('');
    const [isError, setIsError] = useState();
    const [open, setOpen] = useState(false);
    const [error, seterror] = useState({
        error: null,
        message: null,
        key: null,
      });
    
    const { register, handleSubmit, errors } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
          email: "",
          fname: null,
          lname: null,
          phone: "",
        },
      });

    const checkValidation = (e) => {     
        const pwdValue = e.target.value;   
        const pwdName = e.target.name;
        if(pwdName === 'nPassword') {            
            setNPassword(pwdValue);
        } else {
            setConfirmPassword(pwdValue);
        }
        setTimeout(function() {
            if(pwdValue.length < 6) {
                setIsError("Passwords length should be atleast 6");
            } else if ((pwdName === 'nPassword' && pwdValue !== cPassword) || (pwdName === 'cPassword' && pwdValue !== nPassword)) {
                setIsError("Passwords do not match");
            } else {
                setIsError("");
            }
        }, 10);
    };

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
      };

    function SubmitButton() {
        if (nPassword && cPassword && nPassword === cPassword) {
            return <Button fullWidth
                variant="contained"
                color="primary"
                type="submit"
                className={classes.submit}>Create Password</Button>
        } else {
            return <Button disabled fullWidth
                variant="contained"
                color="primary"
                type="submit"
                className={classes.submit}>Create Password</Button>
        };
    };

    const passwordSuccessCallback = (responseData) => {
        console.log('responseData',JSON.stringify(responseData));
        if (responseData.data.message === "SUCCESS") {            
            props.history.push("/auth/congrats", responseData.data.responseData);
        } else {
          seterror({
            error: true,
            message: responseData.data.message,
            key: Math.random(),
          });
        }
    }

    const passwordErrorCallback = (err) => {
        console.log('err',JSON.stringify(err.response));
              let errMsg = "Oops! error in service. Kindly try after sometime or reach out to us at support@skyld.in";
              seterror({
                error: true,
                message: errMsg,
                key: Math.random(),
              });
    }
    const setPassword = async (e) => {
        let details = {
          userName : state.userName,
          otp : state.otp,
          password : nPassword
        };
        callAPIOpen("post",newPasswordUrl,details, passwordSuccessCallback, passwordErrorCallback);
      };

    return (
        <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(setPassword)}
            >
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="nPassword"
                label="New Password"
                type="password"
                id="npassword"
                onChange={e => checkValidation(e)}
            />  
            <FormControl className={clsx(classes.fullwidth)} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={passwordShown ? "text" : "password"}
            value={cPassword}
            onChange={(e) => checkValidation(e)}
            name="cPassword"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisiblity}
                  onMouseDown={togglePasswordVisiblity}
                  edge="end"
                >
                  {passwordShown ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={130}
          />
          </FormControl>
          <div className="confirm-password">
                {isError}
            </div>
            <SubmitButton />
            <NavLink to="/auth/login" variant="body2" className={classes.alink}>
                Signin
            </NavLink>
        </form>
    );
}

export default NewPassword;
