import React, { useState } from "react";
import Documentcard from "Components/Card/DocumentUploadCard";
import ProfileLayout from "Components/Header/Profile";
import {documentUpload, saveAadharCardDetails} from "../../Utils/ApiEndpoints";
import useStyles from "../../assets/jss/material-dashboard-react/components/loginpageStyle";
import axios from "axios";
import { useForm } from "react-hook-form";
import {readCookieByName} from "../../Utils/Session"
import {callAPI} from "../../Utils/HttpUtil";
import TextField from "@material-ui/core/TextField";
import Card from "Components/Card/Card";
import Button from "Components/CustomButtons/Button";	
import CardBody from "Components/Card/CardBody.js";

function AadharCard(props) {
  const [aadhaarfront, setaadhaarfront] = useState();
  const [aadhaarback, setaadhaarback] = useState();
  const [input, setInput] = useState();
  const classes = useStyles();
  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });
  const [values, setValues] = useState({
    aadharCard: null,
   });

  const handleAadhaarfrontupdate = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if(file !== null && file !== undefined) {
          reader.onloadend = () => {
              setaadhaarfront(reader.result);
          }
      reader.readAsDataURL(e.target.files[0]);
      const formData = new FormData();
        formData.append('file',file);   
          const config = {
              headers: {
                  'content-type': 'multipart/form-data',
                  "Authorization": "Bearer "+ readCookieByName('access_token')
              }
          };
          formData.append('docType','AadharCardFrontImage');
      axios
          .post(documentUpload, formData, config)
          .catch((error) => {});
      }
    };
	
	const handleAadhaarbackupdate = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if(file !== null && file !== undefined) {
          reader.onloadend = () => {
              setaadhaarback(reader.result);
          }
      reader.readAsDataURL(e.target.files[0]);
      const formData = new FormData();
        formData.append('file',file);   
          const config = {
              headers: {
                  'content-type': 'multipart/form-data',
                  "Authorization": "Bearer "+ readCookieByName('access_token')
              }
          };
          formData.append('docType','AadharCardBackImage');
      axios
          .post(documentUpload, formData, config)
          .catch((error) => {});
      }
    };

  const onChange = (e) => {
    setInput(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const { register,handleSubmit, errors } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      aadharCardNumber : ""
    },
  });

  function SubmitButton(){
    if (values.aadharCard ){
      return <Button variant="contained"
      color="primary"
      type="submit"
      onClick={handleaadharcardupdate}
      className={classes.submit}> SUBMIT AND NEXT</Button>
    } else {
      return <Button disabled variant="contained"
      color="primary"
      type="submit"
      className={classes.submit}> SUBMIT AND NEXT</Button>
    };
  };

  const handleaadharcardupdate = async (e) => {
    let details = {
      aadharCardNumber : input
    };
    callAPI("post",saveAadharCardDetails,details, suceessAadharCardUpload, errorAadharCardUpload);    
  };

  const suceessAadharCardUpload = (responseData) => {
    console.log('responseData',JSON.stringify(responseData));
    if (responseData.data.message === "SUCCESS") {    
      props.secondary(true);        
      props.onClick();
    } else {
      seterror({
        error: true,
        message: responseData.data.message,
        key: Math.random(),
      });
    }
  }

  const errorAadharCardUpload = (err) => {
    console.log('err',JSON.stringify(err.response));
    let errMsg = "Oops! error in service. Kindly try after sometime or reach out to us at support@skyld.in";       
    seterror({
      error: true,
      message: errMsg,
      key: Math.random(),
    });
  }
  return (
    <div>
      <ProfileLayout
        backAction={props.onClick}
        title={"Aadhaar card details"}
        subtitle={"Upload focused photos of below documents for verification"}
      />
      <form
      className={classes.form}
      autoComplete="off"
      onSubmit={handleSubmit(handleaadharcardupdate)}
      >
        <Card profile>
          <CardBody>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="aadharCard"
              label="Aadhar Card Number"
              name="aadharCard"
              error={!!errors.aadharCard}
              inputRef={register({
                required: "Incorrect Aadhar Card Number!",
                pattern: {
                value: /^\d{12}$/,
                message: "Incorrect Aadhar Card Number!",
                },
              })}
              onChange={onChange}
            />
          </CardBody>
          </Card>
        </form>
          {errors.aadharCard && (
          <span className={classes.error}>{errors.aadharCard.message}</span>
        )} 
      <Documentcard
        description={
          "You can upload front side photo of your Aadhaar card with your clear name and photo"
        }
        selected={aadhaarfront}
        imageHandler={handleAadhaarfrontupdate}
      />
      <Documentcard
        description={
          "You can upload back side photo of your Aadhaar card with your clear name and photo"
        }
        actionUpload={handleaadharcardupdate}
        selected={aadhaarback}
        imageHandler={handleAadhaarbackupdate}
        input={input}
      />
          <SubmitButton/>
    </div>
  );
}

export default AadharCard;
