import { makeStyles } from "@material-ui/core/styles";

const taskStyle = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F5F5FA",
      },
    
      card: {
        marginLeft: "10px",
        marginRight: "10px",
        background: "#FFFFFF",
        borderRadius: "4px",
        opacity: 1,
        marginBottom: "0px",
        backgroundColor: (props) => (props.selected ? "#EAE8FF" : ""),
      },
      text:{
        color:"#474747",
        opacity: 1
      },
      subtext:{
        color:"#eda61f",
        opacity: 1
      },
      callbutton:{
          color:"#FFFFFF"
      },
      chatbutton:{
        backgroundcolor:"#00A9F4"
      },
      btnContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "50px",
        padding: "20px",
      },
      input: {
        height: "40px",
        textAlign: "left",
        fontSize: "16px",
        lineHeight: "20px",
        background: "##F5F5FA",
        borderRadius: "4px",
        color: "##F5F5FA",
        marginTop: "10px",
        padding: "10px",
        opacity: 0.7,
      },
      expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: 'rotate(180deg)',
      },
      button: {
        backgroundColor: (props) => props.backgroundcolor,
        color: "#FFFFFF",
        borderRadius: "25px",
        width: "85px",
        backgroundcolor :"#F7244D58",
                    border :"#FC3D62D8",
        [theme.breakpoints.down("sm")]: {
          width: "auto",
          fontSize: "8px",
        },
        marginRight: "10px",
        fontWeight: "bold",
        fontSize: "8px",
        border: (props) => `1px solid ${props.border}`,
        "&:hover": {
          backgroundColor: (props) => props.backgroundcolor,
        },
      },
  }));
  
  export default taskStyle;