import React from "react";
import Snackbar from "./Snackbar";

export default function AlertMessage({ message }) {
  const [open, setOpen] = React.useState(true);
  

  return (
    <div>
      <Snackbar
        place="tr"
        open={open}
        close={true}
        message={message}
        color="danger"
        closeNotification={() => setOpen(false)}
      />
    </div>
  );
}
