import React ,{useState}from "react";
// core Components
import GridItem from "Components/Grid/GridItem.js";
import GridContainer from "Components/Grid/GridContainer.js";
import Button from "Components/CustomButtons/Button";
import Card from "Components/Card/Card.js";
import TextField from "@material-ui/core/TextField";
import CardAvatar from "Components/Card/CardAvatar.js";
import CardBody from "Components/Card/CardBody.js";
import CardFooter from "Components/Card/CardFooter.js";
//import useStyles from "../../assets/jss/material-dashboard-react/components/loginpageStyle";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import axios from "axios";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};
const useStyles = makeStyles((theme) => ({
    root: {
      alignSelf: 'center',
      justifyContent: "center",
      alignItems: "center",
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: "none",
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  }));

 
  



export default function UserProfile() {
    const classes = useStyles();
    const [imagePreviewUrl, setImagePreviewUrl] = useState("https://www.w3schools.com/howto/img_avatar.png");
  const uploadFile = e =>{
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if(file !== null && file !== undefined) {
        reader.onloadend = () => {
            setImagePreviewUrl(reader.result);
        }
        reader.readAsDataURL(file);
        const formData = new FormData();
        formData.append('file',file);   
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        axios.post("/api/upload",formData,config)
            .then((response) => {
                alert("The file is successfully uploaded");
            }).catch((error) => {
        });
    }
  }
    return (
        <div>
        <p className={classes.cardTitleWhite} style={{color:"black"}}>Edit Profile</p>
            <GridContainer>
            
                <GridItem xs={12} sm={12} md={7}>
                
                    <Card>
                    
                        <CardBody>
                            <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                    <TextField
                                        variant="filled"
                                        margin="normal"
                                        required
                                        fullWidth                                
                                        id="username"
                                        label="First Name"
                                        name="First Name"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                        variant="filled"
                                        margin="normal"
                                        required
                                        fullWidth                                
                                        id="username"
                                        label="Last Name"
                                        name="Last Name"
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                        variant="filled"
                                        margin="normal"
                                        required
                                        fullWidth                                
                                        id="username"
                                        label="User Name"
                                        name="User Name"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                        variant="filled"
                                        margin="normal"
                                        required
                                        fullWidth                                
                                        id="username"
                                        label="E-Mail Id"
                                        name="E-Mail Id"
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                        variant="filled"
                                        margin="normal"
                                        required
                                        fullWidth                                
                                        id="username"
                                        label="City"
                                        name="City"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                        variant="filled"
                                        margin="normal"
                                        required
                                        fullWidth                                
                                        id="username"
                                        label="Country"
                                        name="Country"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                        variant="filled"
                                        margin="normal"
                                        required
                                        fullWidth                                
                                        id="username"
                                        label="Postal Code"
                                        name="Postal Code"
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <GridItem xs={12} sm={12} md={5}>
                        <CardFooter>
                            <Button type="submit"

                                color="primary"
                                className={classes.submit}>Update Profile</Button>
                        </CardFooter>
                        </GridItem>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <Card profile>
                        <CardAvatar profile>
                        <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={uploadFile}/>
                        <label htmlFor="icon-button-file">
                            <IconButton color="primary" aria-label="upload picture" component="span">
                            <Avatar src={imagePreviewUrl} className={classes.large} />
                            </IconButton>
                        </label> 
                        </CardAvatar>
                        <CardBody profile>
                            <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>
                            <h4 className={classes.cardTitle}>Alec Thompson</h4>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}