import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useStyles from "../../assets/jss/material-dashboard-react/components/loginpageStyle";
import Emailimage from "../../../src/assets/img/Email.PNG"
import { useHistory } from "react-router-dom";

function Email(){
    const classes = useStyles();
    const history = useHistory();
    const login = () => {
        history.push("/auth/login");
      };

return (
    <div>
        <Typography component="div">
    
            <Box fontWeight="fontWeightBold" textAlign="center" m={1}>
                Email has been sent!
            </Box>
            <Box fontWeight="fontWeightLight" m={1}>
                Please check your inbox and click on the received link to reset password
            </Box>
            <div className='congrats'><img 
                src={Emailimage}
                alt="Congrats"
            /></div>
            <Button type="submit"
                color="primary"
                fullWidth
                className={classes.submit} onClick={login}>Login</Button>
        </Typography>
    </div>
);

}

export default Email;
