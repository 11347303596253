import React,{ useState, useEffect}  from "react";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import img1 from "assets/img/testimages/Capa_1.jpg";
import Categorycard from "Components/Card/Categorycard";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "Components/CustomButtons/Button";
import { useLocation } from "react-router-dom";
import {updateUserCategories,cityCategories, getUserUrl} from "../../Utils/ApiEndpoints";
import {callAPI} from "../../Utils/HttpUtil";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#F5F5FA",
    flexGrow: 1,
    padding: theme.spacing(2),
  },

  title: {
    marginLeft: "16px",
    textAlign: "left",
    color: "#000000",
    fontSize: "16px",
    lineHeight: "20px",
    fontFamily: "'Poppins', sans-serif;",
    opacity: 1,
  },
  subtitle: {
    marginLeft: "16px",
    textAlign: "left",
    color: "#000000",
    fontSize: "14px",
    lineHeight: "16px",
    fontFamily: "'Poppins', sans-serif;",
    opacity: 0.5,
  },
}));

function Categories(props) {
  const { state } = useLocation();
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [categories, setCategories] = useState([]);
  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });

  const {  handleSubmit } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      username: null,
      categoryIds : []
    },
  });
  if(state === undefined || state === null) {
    props.history.push("/profile/profile");
  }
  
  useEffect(() => {
    { 
      if ((selected !== null || selected !== undefined || selected !=='') && (state.userCategoryDTOList !== null && state.userCategoryDTOList !== undefined && state.userCategoryDTOList.length > 0)){
        props.history.push("/profile/edit", state)
      }
      else 
      callAPI("post",cityCategories,state.cityDTO, categorySuccessCallback, categoryErrorCallback);
    };
  }, []);

const categorySuccessCallback=(responseData ) => {
  console.log('responseData',JSON.stringify(responseData.data.responseData));
  setCategories(responseData.data.responseData);
};

const categoryErrorCallback=(err) => {
  console.log('err',JSON.stringify(err.response));
    let errMsg = "Oops! error in service. Kindly try after sometime or reach out to us at support@skyld.in";
    if(err.response.data.message === "") {
      errMsg = "";
    }          
    seterror({
      error: true,
      message: errMsg,
      key: Math.random(),
    });

};
  
  
  const handleClick = (event, name) => {
    event.preventDefault();
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }; 
  const isSelected = (name) => {return selected.indexOf(name) !== -1};
  

  function SubmitButton(){
    if ( selected.length > 0){
      return <Button 
      variant="contained"
      color="primary"
      type="submit"
      className={classes.submit}>NEXT</Button>
    } else {
      return <Button disabled 
      variant="contained"
      color="primary"
      type="submit"
      className={classes.submit}>NEXT</Button>
    };
  };

  const updateCategorySuccessCallback = (responseData) => {
    console.log('responseData',JSON.stringify(responseData));
    if (responseData.data.message === "SUCCESS") {         
      callAPI("post",getUserUrl,{}, profileSuccessCallback, categoryErrorCallback);        
    } else {
      seterror({
        error: true,
        message: responseData.data.message,
        key: Math.random(),
      });
    }
  }

  const profileSuccessCallback = (responseData) => {
    console.log('responseData',JSON.stringify(responseData));
    if (responseData.data.message === "SUCCESS") { 
      props.history.push("/profile/edit", responseData.data.responseData);
    } else {
      categoryErrorCallback();
    }
  }

  const handleCategory = async () => {
    let details = {
      userName : state.cellPhone,
      categoryIds : selected
      
    };
    callAPI("post",updateUserCategories,details,updateCategorySuccessCallback ,categoryErrorCallback);    
  };

  return (
    <form
      className={classes.form}
      noValidate
      onSubmit={handleSubmit(handleCategory)}
    >
    <div className={classes.card}>
      <div>
        <Typography className={classes.title} variant="body1">
          Register your Account
        </Typography>

        <Typography className={classes.subtitle} variant="body1">
          Select your Categories
        </Typography>
      </div>

      <GridContainer
        spacing={2}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        {categories.map((prop, index) => {
          const isItemSelected = isSelected(prop.categoryId);
          console.log("categoryName", prop.categoryName);
          console.log("categoryId", prop.categoryId);
          return <GridItem xs={4} sm={3} key={index}>
          <Categorycard
            imgurl={img1}
            cardtitle={prop.categoryName}
            onClick={(event) => handleClick(event, prop.categoryId)}
            selected={isSelected(prop.categoryId)}
          />
        </GridItem>
        })}
      </GridContainer>
      <div>
        <SubmitButton/>
      </div>
    </div>
    </form>
  );
}

export default Categories;
