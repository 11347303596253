import React from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import styles from "assets/jss/material-dashboard-react/layouts/profileStyle";
import SplitButton from "../../Components/SplitButton/Splitbutton";
import GridContainer from "Components/Grid/GridContainer.js";
import GridItem from "Components/Grid/GridItem.js";

function Task(props) {
  const classes = styles();

  return (
    <div className={classes.root}>
      <IconButton onClick={props.backAction}>
        <ArrowBackIcon />
      </IconButton>
      <GridContainer>
        <GridItem xs={6} md={8}>
          <Typography className={classes.title}>{props.title}</Typography>
          <Typography className={classes.subtitle}>{props.subtitle}</Typography>
          </GridItem>
          <GridItem xs={6} md={4}>
          <SplitButton style={{marginTop : "20px"}}
                        backgroundcolor="#f5f5fa"
                        border="#5244ec"
          />
        </GridItem>
       </GridContainer>
    </div>
  );
}

export default Task;
