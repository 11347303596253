import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link,NavLink } from "react-router-dom";
import useStyles from "../../assets/jss/material-dashboard-react/views/forgotPassword";
import {callAPIOpen} from "../../Utils/HttpUtil";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useForm } from "react-hook-form";
import Emailimage from "../../../src/assets/img/Email.PNG"
import { useHistory } from "react-router-dom";
import AlertMessage from "../../Components/Snackbar/SnackbarError";
import {forgotPasswordUrl} from "../../Utils/ApiEndpoints";


function ForgotPassword(props) {
  const classes = useStyles();
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const [values, setValues] = useState({
    username: null,
  });

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      username: null
    },
  });

  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });
  const forgotPasswordSuccessCallback = (responseData) => {
    console.log('responseData',JSON.stringify(responseData));
    if (responseData.data.message === "SUCCESS") {            
      setSuccess(true);
    } else {
      seterror({
        error: true,
        message: "Invalid User. Please signup",
        key: Math.random(),
      });
    }
  }

  const forgotPasswordErrorCallback = (err) => {
    console.log('err',JSON.stringify(err.response));
    let errMsg = "Oops! error in service. Kindly try after sometime or reach out to us at support@skyld.in";       
    seterror({
      error: true,
      message: errMsg,
      key: Math.random(),
    });
  }

  const handleForgotPassword = (e) => {
      let details = {
        userName: values.username,
      };
      callAPIOpen("post",forgotPasswordUrl,details, forgotPasswordSuccessCallback, forgotPasswordErrorCallback);
  };

  const login = () => {
    history.push("/auth/login");
  };

  function SubmitButton(){
    if (values.username){
      return <Button fullWidth
      variant="contained"
      color="primary"
      type="submit"
      className={classes.submit}>SEND</Button>
    } else {
      return <Button disabled fullWidth
      variant="contained"
      color="primary"
      type="submit"
      className={classes.submit}>SEND</Button>
    };
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(handleForgotPassword)}>
    {success ? (
      <div>
        <Typography component="div">
            <Box fontWeight="fontWeightBold" className={classes.boxhead} textAlign="left" m={1}>
                Email has been sent!
            </Box>
            <Box fontWeight="fontWeightLight" className={classes.boxbody} m={1}>
                Please check your inbox and click on the received link to reset password
            </Box>
            <div className='congrats'><img 
                src={Emailimage}
                alt="Congrats"
            /></div>
            <Button type="submit"
                color="primary"
                fullWidth
                className={classes.submit} onClick={login}>Login</Button>
        </Typography>
        <p className={classes.parag}>{"Did’t received the link? "}
          <Link to="#" variant="body2"  onClick = {handleForgotPassword}>
            Resend
          </Link>
        </p>
    </div>
      ) : (
        <>
        {error.error ? (
        <AlertMessage key={error.key} message={error.message} />
      ) : null}
     <Typography component="div">
        <Box fontWeight="fontWeightBold" className={classes.boxhead} textAlign="left" m={1}>
             Forgot Password
        </Box>
        <Box fontWeight="fontWeightLight" className={classes.boxbody} m={1}>
          Enter your registered email below to receive password
        </Box>
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        onChange={onChange}
        id="username"
        error={!!errors.username}
        label="E-Mail/Phone Number"
        name="username"
        inputRef={register({
          required: "You must provide the E-mail Address/Phone Number!",
          pattern: {
            message: "You must provide the E-mail Address/Phone Number!",
          },
          
        })}
      />
      {errors.username && (
        <span className={classes.error}>{errors.username.message}</span>
      )}
      <SubmitButton/>
      <p className={classes.parag}>{"Remember password? "}
      <NavLink to="/auth/login" variant="body2" >
        Login
      </NavLink></p>
      </>
      )}
    </form>
  );
}

export default ForgotPassword;
