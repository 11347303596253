import React, { useState} from "react";
import Documentcard from "Components/Card/DocumentUploadCard";
import ProfileLayout from "Components/Header/Profile";
import axios from "axios";
import {documentUpload, savePanCardDetails} from "../../Utils/ApiEndpoints";
import useStyles from "../../assets/jss/material-dashboard-react/components/loginpageStyle";
import { useForm } from "react-hook-form";
import {readCookieByName} from "../../Utils/Session"
import {callAPI} from "../../Utils/HttpUtil";
import TextField from "@material-ui/core/TextField";
import Button from "Components/CustomButtons/Button";	
import Card from "Components/Card/Card";
import CardBody from "Components/Card/CardBody.js";

function Pancard(props) {
  const classes = useStyles();
  const [pancard, setpancard] = useState();
  const [input, setInput] = useState();
  const [values, setValues] = useState({
   panCard: null,
  });
  const {selected, actionUpload} =props;
  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });


  const onChange = (e) => {
    setInput(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  
  const { register, errors , handleSubmit } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      panCardNumber : ""
    },
  });

  function SubmitButton(){
    if (values.panCard ){
      return <Button 
      variant="contained"
      color="primary"
      type="submit"
      onClick={handlepancardupdate}
      className={classes.submit}> SUBMIT AND NEXT</Button>
    } else {
      return <Button 
      disabled 
      variant="contained"
      color="primary"
      type="submit"
      className={classes.submit}> SUBMIT AND NEXT</Button>
    };
  };

  const handlepancardupdate = async (e) => {
    let details = {
      panCardNumber : input
    };
    callAPI("post",savePanCardDetails,details, suceessPanCardUpload, errorPanCardUpload);    
  };

  const suceessPanCardUpload = (responseData) => {
    console.log('responseData',JSON.stringify(responseData));
    if (responseData.data.message === "SUCCESS") {    
      props.secondary(true);        
      props.onClick();
    } else {
      seterror({
        error: true,
        message: responseData.data.message,
        key: Math.random(),
      });
    }
  }
  
  const errorPanCardUpload = (err) => {
    console.log('err',JSON.stringify(err.response));
    let errMsg = "Oops! error in service. Kindly try after sometime or reach out to us at support@skyld.in";       
    seterror({
      error: true,
      message: errMsg,
      key: Math.random(),
    });
  }

  const handlepancardimage = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if(file !== null && file !== undefined) {
          reader.onloadend = () => {
              setpancard(reader.result);
          }
      reader.readAsDataURL(e.target.files[0]);
      const formData = new FormData();
        formData.append('file',file);   
          const config = {
              headers: {
                  'content-type': 'multipart/form-data',
                  "Authorization": "Bearer "+ readCookieByName('access_token')
              }
          };
          formData.append('docType','PanCardImage');
      axios
          .post(documentUpload, formData, config)
          .catch((error) => {});
      }
    };

  return (
    <>
      <ProfileLayout
        title={"Pan card details"}
        subtitle={"Upload focused photos of below documents for verification"}
        backAction={props.onClick}
      />
      <form
      className={classes.form}
      autoComplete="off"
      onSubmit={handleSubmit(handlepancardupdate)}
      >
              <Card profile>
              <CardBody>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="panCard"
                    label="Pan Card Number"
                    name="panCard"
                    error={!!errors.panCard}
                    inputRef={register({
                      required: "Incorrect Pan Card Number!",
                      pattern: {
                      value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                      message: "Incorrect Pan Card Number!",
                      },
                    })}
                    onChange={onChange}
                  />
                  </CardBody>
                  </Card>
                  </form>
                    {errors.panCard && (
                    <span className={classes.error}>{errors.panCard.message}</span>
                  )} 
        <Documentcard
            description={
              "You can upload front side photo of your pan card with your clear name and photo"
            }
            actionUpload={handlepancardupdate}
            selected={pancard}
            imageHandler={handlepancardimage}
            input={input}
      />
          <SubmitButton/>
    </>
    
  );

}
export default Pancard;
