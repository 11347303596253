import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { NavLink } from "react-router-dom";
import useStyles from "../../assets/jss/material-dashboard-react/components/loginpageStyle";
import { useForm } from "react-hook-form";
import AlertMessage from "../../Components/Snackbar/SnackbarError";
import {callAPIOpen} from "../../Utils/HttpUtil";
import {signupUrl} from "../../Utils/ApiEndpoints";

function SignUp(props) {

  const [values, setValues] = useState({
      email: "",
      fname: null,
      lname: null,
      phone: "",
  });


  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      email: "",
      fname: null,
      lname: null,
      phone: "",
    },
  });

  const classes = useStyles();

  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  function SubmitButton(){
    if (values.fname && values.lname && values.email && values.phone){
      return <Button fullWidth
      variant="contained"
      color="primary"
      type="submit"
      className={classes.submit}>Sign Up</Button>
    } else {
      return <Button disabled fullWidth
      variant="contained"
      color="primary"
      type="submit"
      className={classes.submit}>Sign Up</Button>
    };
  };

  const signupSuccessCallback = (responseData) => {
    console.log('responseData',JSON.stringify(responseData));
    if (responseData.data.message === "SUCCESS") {            
      props.history.push("/auth/verifyotp", responseData.data.responseData);
    } else {
      seterror({
        error: true,
        message: responseData.data.message,
        key: Math.random(),
      });
    }
  }

  const signupErrorCallback = (err) => {
    console.log('err',JSON.stringify(err.response));
    let errMsg = "Oops! error in service. Kindly try after sometime or reach out to us at support@skyld.in";
    if(err.response.data.message === "EMAIL_EXISTS") {
      errMsg = "Already signed up. Verify OTP or Login to application if verification completed";
    }          
    seterror({
      error: true,
      message: errMsg,
      key: Math.random(),
    });
  }

  const handlesignup = async (e) => {
    let details = {
      firstName: values.fname,
      lastName: values.lname,
      email: values.email,
      cellPhone: values.phone
    };
    callAPIOpen("post",signupUrl,details, signupSuccessCallback, signupErrorCallback);    
  };

  return (
    <form
      className={classes.form}
      noValidate
      onSubmit={handleSubmit(handlesignup)}
    >
    {error.error ? (
        <AlertMessage key={error.key} message={error.message} />
      ) : null}
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="fname"
        label="First Name"
        id="fname"
        error={!!errors.fname}
        inputRef={register({
          required: "You must provide the First name!",
          pattern: {
            value: /^[a-zA-Z]*$/,
            message: "You must provide the First name!",
          },
        })}
        onChange={onChange}
      />
      {errors.fname && (
        <span className={classes.error}>{errors.fname.message}</span>
      )}
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="lname"
        label="Last Name"
        id="lname"
        error={!!errors.lname}
        inputRef={register({
          required: "You must provide the last name!",
          pattern: {
            value: /^[a-zA-Z]*$/,
            message: "You must provide the last name!",
          },
          
        })}
        onChange={onChange}
      />
      {errors.lname && (
        <span className={classes.error}>{errors.lname.message}</span>
      )}
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        autoComplete="email"
        error={!!errors.email}
        label="Email Address"
        name="email"
        inputRef={register({
          required: "You must provide the email address!",
          pattern: {
            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "You must provide a valid email address!",
          },
          
        })}
        onChange={onChange}
      />
      {errors.email && (
        <span className={classes.error}>{errors.email.message}</span>
      )}
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="phone"
        label="Phone Number"
        id="phone"
        error={!!errors.phone}
        inputRef={register({
          required: "You must provide the Phone Number!",
          pattern: {
            value: /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/,
            message: "You must provide the Phone Number!",
          },
          
        })}
        onChange={onChange}
      />
      {errors.phone && (
        <span className={classes.error}>{errors.phone.message}</span>
      )}
      <div>
      <SubmitButton/>
      <NavLink to="/auth/login" variant="body2" className={classes.alink}>
        Signin
      </NavLink>
      </div>
    </form>
  );
}

export default SignUp;
