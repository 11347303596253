import React, { useState, useEffect } from "react";
import className from "classnames";
import CardHeader from "Components/Card/CardHeader";
import Typography from "@material-ui/core/Typography";
import DoneIcon from "@material-ui/icons/Done";
import ListItem from "Components/ListItems/ListItem";
import Button from "Components/CustomButtons/CustomButton";
import PersonalDocuments from "./PersonalDocuments";
import BankDetails from "./BankDetails";
import Skills from "./Skills";
import PersonlDetails from "views/UserManagement/UserProfile";
import ServiceArea from "./ServiceArea";
import styles from "assets/jss/material-dashboard-react/views/userProfile";
import {vendorDocumentDetails} from "../../Utils/ApiEndpoints";
import {callAPI} from "../../Utils/HttpUtil";
import { useLocation } from "react-router-dom";

function ProfileSection() {
  const { state } = useLocation();
  const [route, setroute] = useState();
  const [vendorData , setVendorData] = useState();
  const [status, setStatus] = useState({
    Pending: false,
    UnderVerification: false,
    Completed: true
  });
  const classes = styles();
  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  useEffect(() => {
      callVendorDocuments();
  }, []);

  const callVendorDocuments = () => {
    callAPI("post",vendorDocumentDetails,{},profileSuccessCallback,profileErrorCallback);
  }

  const profileSuccessCallback=(responseData ) => {
    let vendorData = responseData.data.responseData;
    console.log('responseData',JSON.stringify(vendorData));
    const personalDetails = {
      id: "1",
      name: "Personal Details",
      status: "Completed",
      //path: "PersonlDetails",
    }

    let personalDocumentStatus = "Pending";
    if(vendorData.personalDocuments.statusPanCard === 'Pending' || vendorData.personalDocuments.statusAadhar === 'Pending') {
      personalDocumentStatus = "Pending";
    } else if (vendorData.personalDocuments.statusPanCard === 'Verification' && vendorData.personalDocuments.statusAadhar === 'Verification') {
      personalDocumentStatus = "Under Verification";
    } else if (vendorData.personalDocuments.statusPanCard === 'Approved' && vendorData.personalDocuments.statusAadhar === 'Approved') {
      personalDocumentStatus = "Completed";
    } 

    let skillsStatus = "Pending";
    vendorData.userSkillsDTO.map((skill, index) =>{
    if(skill.status === 'Verification') {
      skillsStatus = 'Under Verification'
    } else if(skill.status === 'Approved' && skillsStatus !== 'Under Verification') {
      skillsStatus = 'Completed'
    }
  })

    let serviceAreaStatus = "Pending";
    vendorData.serviceArea.map((serviceArea, index) => {
      if(serviceArea.status === 'Verification') {
        serviceAreaStatus = 'Under Verification'
      } else if(serviceArea.status === 'Approved' && serviceAreaStatus !== 'Under Verification') {
        serviceAreaStatus = 'Completed'
      }
    })
    

    let bankStatus = "Pending";
    if(vendorData.bankDetails.status === 'Verification') {
      bankStatus = 'Under Verification'
    } else if(vendorData.bankDetails.status === 'Approved') {
      bankStatus = 'Completed'
    }

    let pending = false;
    let underVerification = false;
  
    if(bankStatus === 'Pending' || personalDocumentStatus === 'Pending' || serviceAreaStatus === 'Pending' || skillsStatus === 'Pending') {
      pending = true;
    }
    if(bankStatus === 'Under Verification' || personalDocumentStatus === 'Under Verification' || serviceAreaStatus === 'Under Verification' || skillsStatus === 'Under Verification') {
      underVerification = true;
    }

    setStatus({...status, Pending : pending, UnderVerification : underVerification});
    const personalDocuments = {
      id: "2",
      name: "Personal Documents",
      status: personalDocumentStatus,
      path: "PersonalDocuments",
    }

    const skills={
      id: "3", 
      name: "Skills", 
      status: skillsStatus, 
      path: "Skills" 
    }

    const serviceArea={
      id: "4",
      name: "Add Service Area",
      status: serviceAreaStatus,
      path: "ServiceArea",
    }

    const bankObj = {
      id : "5",
      name : "Bank Details",
      status: bankStatus,
      path : "BankDetails"
    }
    data.length = 0;
    data.push(personalDetails);
    data.push(personalDocuments);
    data.push(skills);
    data.push(serviceArea);
    data.push(bankObj);
    console.log("Data in callback",data);
    setLoading(false);
    vendorData.userCategoryDTOList = state.userCategoryDTOList;
    setVendorData(vendorData);
  };
  
  const profileErrorCallback=(err) => {
    console.log('err',JSON.stringify(err.response));
      let errMsg = "Oops! error in service. Kindly try after sometime or reach out to us at support@skyld.in";
      if(err.response.data.message === "") {
        errMsg = "";
      }          
      seterror({
        error: true,
        message: errMsg,
        key: Math.random(),
      });
  
  };

  const components = {
    PersonlDetails: PersonlDetails,
    PersonalDocuments: PersonalDocuments,
    Skills: Skills,
    BankDetails: BankDetails,
    ServiceArea: ServiceArea,
  };

  const CustomRoute = components[route];

  const clickBackCallback =() => {
    callVendorDocuments();
    setroute(null);
  }

  function CustomList(props) {
    console.log("Data in custom",data);
    return (
      <div style={{ marginTop: "20px" }}>
        <span className={classes.span}>{props.status}</span>
        {data.map((prop, index) => {
          if (prop.status === props.status) {
            return (
              <ListItem
                doneIcon={props.icon}
                lable={prop.name}
                key={index}
                disabled={props.status==='Pending'?false:true}
                onClick={props.status==='Pending'?() => setroute(prop.path):null}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  }

  return (
    <div>
      {loading? null : (!route ? (
        <div className={className(classes.root, classes.paddingTop)}>
          <CardHeader className={classes.cardHeader}>
            <Typography>
              Please complete below steps to complete your profile registration
            </Typography>
          </CardHeader>
          {status.Pending ? (
            <CustomList
              status={"Pending"}
              icon={<DoneIcon fontSize="small" />}
            />
          ) : null}
          {status.UnderVerification ? (
            <CustomList
              status={"Under Verification"}
              icon={
                <DoneIcon
                  fontSize="small"
                  style={{ color: "#FFD110", opacity: 1 }}
                />
              }
            />
          ) : null}
          {status.Completed ? (
            <CustomList
              status={"Completed"}
              icon={
                <DoneIcon
                  fontSize="small"
                  style={{ color: "#00C361", opacity: 1 }}
                />
              }
            />
          ) : null}
          {!status.Pending && !status.UnderVerification ? (
            <div>
            <Button>CLICK TO CONTINUTE</Button>
            </div>
          ) : null}
        </div>
      ) : (
        <CustomRoute vendorData={vendorData} onClick={() => clickBackCallback()} />
      ))}
    </div>
  );
}

export default ProfileSection;
