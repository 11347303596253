export const signupUrl ="/openapi/signup";
export const verifyTokenUrl ="/openapi/verifytoken";
export const verifyOtpUrl ="/openapi/verifyotp";
export const newPasswordUrl ="/openapi/setnewpassword";
export const forgotPasswordUrl ="/openapi/forgotpassword";
export const resendOtpUrl ="/openapi/resendotp";
export const getUserUrl="/api/user-management/users/getUser";
export const addUserUrl="/api/user-management/users/addUserDetails";
export const getCities="/api/admin/city/getcities";
export const uploadImage="/api/user-management/users/imageupload";
export const categoryList="/api/user-management/category/userCategoryList";
export const userCategories="/api/user-management/category/updateUserCategories";
export const cityCategories="/api/admin/category/getCityCategories";
export const updateUserCategories="/api/user-management/category/updateUserCategories";
export const vendorDocumentDetails="/api/user-management/users/getVendorDocumentsDetails";
export const savePanCardDetails="/api/user-management/users/savePanCardDetails";
export const documentUpload="/api/user-management/users/uploadDocuments";
export const saveAadharCardDetails = "/api/user-management/users/saveAadharCardDetails";
export const saveChequeDetails ="/api/user-management/users/uploadVendorBankDetailsProof";
export const addVendorBankDetails ="/api/user-management/users/addVendorBankDetails";
export const serviceAreaToUser ="/api/user-management/users/mapServiceAreaToUser";
export const cityServiceArea ="/api/admin/city/getCityServiceAreas";
export const skillsToUser ="api/user-management/users/addSkillsToUser";
export const cityCategorySkills ="/api/admin/category/getCityCategorySkills";