import React from "react";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import useToolbarStyles from "assets/jss/material-dashboard-react/components/tabletoolbarStyle";
import classNames from "classnames";

const TableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, TableTitle, Icon, color } = props;
  const cardHeaderClasses = classNames({
    [classes[color + "Toolbar"]]: color,
  });

  return (
    <Toolbar className={cardHeaderClasses}>
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {TableTitle}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon style={{ color: "#FFFFFF" }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list" style={{ color: "#FFFFFF" }}>
            {Icon}
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default TableToolbar;
