import React, { useState, useEffect } from "react";
import ListItem from "Components/ListItems/ListItem";
import ProfileLayout from "Components/Header/Profile";
import AadharCard from "views/UserProfileScreens/AadharCard";
import Pancard from "views/UserProfileScreens/Pancard";

function PersonalDocuments(props) {
  
  const [route, setRoute] = useState("none");
  const [status, setStatus] = useState();
  const [secondaryPan, setSecondaryPan] = useState();
  const [secondaryAadhar, setSecondaryAadhar] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {      
    console.log("props",props.vendorData);
    if(props.vendorData.personalDocuments.statusAadhar !== 'Pending') {
      setSecondaryAadhar(true);
    }
    if(props.vendorData.personalDocuments.statusPan !== 'Pending') {
      setSecondaryPan(true);
    }
    setLoading(false);
  }, []);

  return (
    <div>
      {loading ? null :
          (route === "Pan" ? (
        <>
          <Pancard
            secondary = {setSecondaryPan}
            onClick={() => {
              setRoute("none");
            }}
          />
        </>
      ) : route === "Aadhaar" ? (
        <>
          <AadharCard
          secondary = {setSecondaryAadhar}
            onClick={() => {
              setRoute("none");
            }}
          />
        </>
      ) : (
        <>
          <ProfileLayout
            title={"Personal documents"}
            subtitle={
              "Upload focused photos of below documents for verification"
            }
            backAction={props.onClick}
          />
          <ListItem
            lable={"Pan Card"}
            status={status}            
            secondary ={secondaryPan}
            disabled={secondaryPan}
            onClick={() => {
              setRoute("Pan");
            }}
          />
          <ListItem
            status={status}
            lable={"Aadhaar Card"}
            secondary ={secondaryAadhar}
            disabled={secondaryAadhar}
            onClick={() => {
              setRoute("Aadhaar");
            }}
          />
        </>
      ))}
    </div>
  );
}

export default PersonalDocuments;
